/* eslint-disable react/jsx-fragments */
import {
  Fragment
} from 'react';

import {
  FormattedMessage,
  useIntl
} from 'react-intl';

import {
  Field
} from 'react-final-form';

import {
  Col,
  MultiSelection,
  Row,
  Select,
  TextField
} from '@folio/stripes/components';

import {
  required
} from '@folio/stripes/util';

const InstitutionUserForm = ({ createEditDetails, _onSISelect }) => {
  const intl = useIntl();
  return (
    <Fragment>
      <Row>
        <Col xs={12}>
          <Field
            id="edit-institutionUser-name"
            name="name"
            label={<FormattedMessage id="ui-ill-ui.institution.user.name" />}
            component={TextField}
            required
            validate={required}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            id="edit-institutionUser-folioUserId"
            name="folioUserId"
            label={<FormattedMessage id="ui-ill-ui.institution.user.folioUserId" />}
            placeholder={intl.formatMessage({ id: 'ui-ill-ui.institution.user.folioUserId.select.placeHolder' })}
            component={Select}
            dataOptions={createEditDetails.folioUsers}
            required
            validate={required}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            id="edit-institutionUser-institutionManaging"
            name="institutionManaging.id"
            label={<FormattedMessage id="ui-ill-ui.institution.user.managing" />}
            placeholder={intl.formatMessage({ id: 'ui-ill-ui.institution.user.managing.select.placeHolder' })}
            component={Select}
            dataOptions={createEditDetails.institutions}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            id="edit-institutionUser-groups"
            name="tGroups"
            label={<FormattedMessage id="ui-ill-ui.institution.user.groups" />}
            placeholder={intl.formatMessage({ id: 'ui-ill-ui.institution.user.groups.select.placeHolder' })}
            component={MultiSelection}
            dataOptions={createEditDetails.groups}
            onBlur={e => { e.preventDefault(); }}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default InstitutionUserForm;
