import {
  FormattedMessage,
  useIntl
} from 'react-intl';

import {
  useQIndex
} from '@k-int/stripes-kint-components';

import {
  Button,
  Icon,
  SearchField
} from '@folio/stripes/components';

const SystemSearch = ({
  resetAll,
  searchHandlers,
  searchValue,
  searchChanged,
  filterChanged,
  searchableIndexes
}) => {
  const intl = useIntl();
  const [qIndex, setQIndex] = useQIndex();
  const searchableIndexesUpdated = searchableIndexes.map(x => ({
    label: intl.formatMessage({ id: `ui-ill-ui.systemSearchField.${x.label}` }),
    value: x.value,
  }));

  return (
    <>
      <SearchField
        autoFocus
        name="query"
        onChange={searchHandlers.query}
        onClear={searchHandlers.reset}
        searchableIndexes={searchableIndexesUpdated}
        selectedIndex={qIndex}
        onChangeIndex={e => setQIndex(e?.target?.value)}
        value={searchValue.query}
      />
      <Button
        buttonStyle="primary"
        disabled={!searchValue.query || searchValue.query === ''}
        fullWidth
        type="submit"
      >
        <FormattedMessage id="stripes-smart-components.search" />
      </Button>
      <Button
        buttonStyle="none"
        disabled={!(filterChanged || searchChanged)}
        id="clickable-reset-all"
        fullWidth
        onClick={resetAll}
      >
        <Icon icon="times-circle-solid">
          <FormattedMessage id="stripes-smart-components.resetAll" />
        </Icon>
      </Button>
    </>
  );
};

export default SystemSearch;
