import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useIntlKeyStore } from '@k-int/stripes-kint-components';
import PatronRequestsRoute from './routes/PatronRequestsRoute';
import CreateEditRoute from './routes/CreateEditRoute';
import ViewRoute from './routes/ViewRoute';
import PullSlipRoute from './routes/PullSlipRoute';
import { EditPullslipNotification } from './settings/pullslipNotifications';
import IllSettings from './settings';
import AppNameContext from './AppNameContext';
import StaleBundleWarning from './components/StaleBundleWarning';
import InstitutionsRoute from './routes/System/Institutions/InstitutionsRoute';
import InstitutionGroupsRoute from './routes/System/Institutions/InstitutionGroupsRoute';
import InstitutionUsersRoute from './routes/System/Institutions/InstitutionUsersRoute';
import SharedIndexQueryRoute from './routes/System/SharedIndex/SharedIndexQueryRoute';
import IllSystemSettings from './components/System/IllSystemSettings/IllSystemSettings';

const ResourceSharing = (props) => {
  const {
    actAs,
    match: { path },
    location: { search }
  } = props;

  const appName = path.substring(1).replace(/\/.*/, '');
  props.stripes.logger.log('appName', `us-ill: path='${path}', appName='${appName}'`);

  // stripes-kint-components no longer contains translations for its strings and needs to know where to look
  const addKey = useIntlKeyStore(state => state.addKey);
  addKey('stripes-ill');

  if (actAs === 'settings') {
    return <IllSettings {...props} appName={appName} />;
  }
  return (
    <AppNameContext.Provider value={appName}>
      {/* TODO: remove after switching to the Stripes version from a future release
      which will include this functionality in stripes-ui */}
      <StaleBundleWarning />
      <Switch>
        <Redirect
          exact
          from={path}
          to={`${path}/requests`}
        />
        {appName === 'request' &&
          <Route path={`${path}/requests/create`} component={CreateEditRoute} />
        }
        {appName === 'request' &&
          <Route path={`${path}/requests/edit/:id`} component={CreateEditRoute} />
        }
        <Redirect
          exact
          from={`${path}/requests/view/:id`}
          to={`${path}/requests/view/:id/flow${search}`}
        />
        <Route path={`${path}/requests/view/:id/pullslip`} component={PullSlipRoute} />
        <Route path={`${path}/requests/batch/:batchId/pullslip`} component={PullSlipRoute} />

        {/* Contains nested routes: ./details and ./flow */}
        <Route path={`${path}/requests/view/:id`} component={ViewRoute} />

        <Route
          path={`${path}/requests/:action?`}
          render={(p) => <PatronRequestsRoute {...p} appName={appName} />}
        />
        <Route path={`${path}/pullslip-notifications/:id/edit`} component={EditPullslipNotification} />
        <Route
          path={`${path}/institutions`}
          render={(p) => <InstitutionsRoute {...p} />}
        />
        <Route
          path={`${path}/institutionGroups`}
          render={(p) => <InstitutionGroupsRoute {...p} />}
        />
        <Route
          path={`${path}/institutionUsers`}
          render={(p) => <InstitutionUsersRoute {...p} />}
        />

        <Route
          path={`${path}/sharedIndexQuery`}
          render={(p) => <SharedIndexQueryRoute {...p} />}
        />

        <Route
          path={`${path}/illSystemSettings`}
          component={IllSystemSettings}
        />
      </Switch>
    </AppNameContext.Provider>
  );
};

export default ResourceSharing;

export * from './constants/endpoints';
export { REQUEST_ACTIONS } from './constants/requestActions';

export { DeveloperJson } from './components/DeveloperJson';
