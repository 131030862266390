import { Link } from 'react-router-dom';

const GroupTable = ({ groups }) => {
  return (
    <table>
      <tbody>
        {
          groups.map((entryGroup, i) => (
            <tr key={`group-${i}`}>
              <td>
                <Link to={`../institutionGroup/${entryGroup.id}`}>
                  {entryGroup.name}
                </Link>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
};

export default GroupTable;
