/* eslint-disable jsx-a11y/control-has-associated-label */
// (it doesn't recognise FormattedMessage as a text label for th)
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Card } from '@folio/stripes/components';

const GenericInfo = ({
  titleId,
  list,
  linkPath,
  cssCard,
  cssCardHeader,
  cssTable,
  descriptionProperty,
  descriptionId
}) => {
  return (
    <Card
      id={`${linkPath}-generic-card`}
      headerStart={<FormattedMessage id={`ui-ill-ui.${titleId}`} />}
      cardClass={cssCard}
      headerClass={cssCardHeader}
      roundedBorder
    >
      <table className={cssTable}>
        <thead>
          <tr>
            <th><FormattedMessage id="ui-ill-ui.name" /></th>
            <th><FormattedMessage id={`ui-ill-ui.${descriptionId}`} /></th>
          </tr>
        </thead>
        <tbody>
          {
            list.map((entry, i) => (
              <tr key={i}>
                <td>
                  <Link to={`../${linkPath}/${entry.id}`}>
                    {entry.name}
                  </Link>
                </td>
                <td>
                  {entry[descriptionProperty]}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </Card>
  );
};

GenericInfo.defaultProps = {
  descriptionProperty: 'description',
  descriptionId: 'description'
};

export default GenericInfo;
