/* eslint-disable react/jsx-fragments */
import {
  Fragment
} from 'react';

import {
  FormattedMessage
} from 'react-intl';

import {
  Row,
  Col,
  KeyValue
} from '@folio/stripes/components';

import {
  useOkapiQuery
} from '@k-int/stripes-ill';

import {
  INSTITUTION_GROUP_WITH_SEPARATOR_ENDPOINT
} from '../../../constants/endpoints';

import GroupInstitutionInfo from './sections/GroupInstitutionInfo';
import GroupUserInfo from './sections/GroupUserInfo';

const InstitutionGroup = ({ id }) => {
  const institutionGroupQuery = useOkapiQuery(`${INSTITUTION_GROUP_WITH_SEPARATOR_ENDPOINT}${id}`);

  // If query not a success just return
  if (!institutionGroupQuery.isSuccess) {
    return null;
  }
  const record = institutionGroupQuery.data;

  return (
    <Fragment>
      <Row>
        <Col xs={12}>
          <KeyValue
            label={<FormattedMessage id="ui-ill-ui.institution.group.name" />}
            value={record.name}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <KeyValue
            label={<FormattedMessage id="ui-ill-ui.institution.group.description" />}
            value={record.description}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <GroupInstitutionInfo
            institutions={record.institutions}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <GroupUserInfo
            users={record.institutionUsers}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default InstitutionGroup;
