/* eslint-disable jsx-a11y/control-has-associated-label */
// (it doesn't recognise FormattedMessage as a text label for th)
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Card } from '@folio/stripes/components';
import { useOkapiQuery } from '@k-int/stripes-ill';
import { INSTITUTION_WITH_SEPARATOR_ENDPOINT } from '../../../../constants/endpoints';
import GroupTable from './GroupTable';
import css from './UserInfo.css';

const UserInfo = ({ parentId }) => {
  const { data: users = {}, isSuccess } = useOkapiQuery(`${INSTITUTION_WITH_SEPARATOR_ENDPOINT}${parentId}/users`);

  // Have we managed to get the data
  if (!isSuccess) {
    return (null);
  }

  return (
    <Card
      id={`${parentId}-users-card`}
      headerStart={<FormattedMessage id="ui-ill-ui.institution.users" />}
      cardClass={css.userCard}
      headerClass={css.userCardHeader}
      roundedBorder
    >
      <table className={css.userEntryList}>
        <thead>
          <tr>
            <th><FormattedMessage id="ui-ill-ui.institution.user.name" /></th>
            <th><FormattedMessage id="ui-ill-ui.institution.user.managing" /></th>
            <th><FormattedMessage id="ui-ill-ui.institution.user.groups" /></th>
          </tr>
        </thead>
        <tbody>
          {
            users.map((entry, i) => (
              <tr key={i}>
                <td>
                  <Link to={`../institutionUser/${entry.user.id}`}>
                    {entry.user.name}
                  </Link>
                </td>
                <td>
                  <Link to={`../institution/${entry.institutionManaging?.id}`}>
                    {entry.institutionManaging?.name}
                  </Link>
                </td>
                <td>
                  <GroupTable groups={entry.groups} />
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </Card>
  );
};

export default UserInfo;
