import {
  useLocation
} from 'react-router-dom';

import {
  FormattedMessage,
} from 'react-intl';

import {
  IfPermission
} from '@folio/stripes/core';

import {
  Button,
  Pane,
  PaneMenu,
  Paneset
} from '@folio/stripes/components';

import {
  useSettings
} from '@k-int/stripes-kint-components';

import {
  REFDATA_ENDPOINT,
  SETTINGS_SYSTEM_ENDPOINT,
  TEMPLATES_ENDPOINT
} from '../../../constants/endpoints';

import {
  SYSTEM_AREA_INSTITUTION,
  SYSTEM_AREA_INSTITUTION_GROUP,
  SYSTEM_AREA_INSTITUTION_USER,
  SYSTEM_AREA_SHARED_INDEX_QUERY
} from '../SystemAreas';

const IllSystemSettings = (props) => {
  const location = useLocation();
  const buttonPathPrefix = location?.pathname?.endsWith('illSystemSettings') ? '' : '../';

  const { isLoading, SettingsComponent } = useSettings({
    intlKey: 'ui-ill-ui',
    persistentPages: [],
    dynamicPageExclusions: [],
    refdataEndpoint: REFDATA_ENDPOINT,
    settingEndpoint: SETTINGS_SYSTEM_ENDPOINT,
    templateEndpoint: TEMPLATES_ENDPOINT
  });

  if (isLoading) {
    return null;
  }

  const menuLinks = [
    SYSTEM_AREA_INSTITUTION,
    SYSTEM_AREA_INSTITUTION_GROUP,
    SYSTEM_AREA_INSTITUTION_USER,
    SYSTEM_AREA_SHARED_INDEX_QUERY
  ];

  return (
    <Paneset>
      <Pane
        paneTitle={<FormattedMessage id="ui-ill-ui.system.settings" />}
        defaultWidth="100%"
        lastMenu={(
          <PaneMenu>
            {
              menuLinks.map((entry, i) => (
                <IfPermission perm={entry.permission}>
                  <Button
                    buttonStyle="primary"
                    id={`clickable-ill-system-settings-${entry.link}`}
                    key={i}
                    marginBottom0
                    to={`${buttonPathPrefix}${entry.link}`}
                  >
                    <FormattedMessage id={`ui-ill-ui.${entry.linkMessageId}`} />
                  </Button>
                </IfPermission>
              ))
            }
          </PaneMenu>
        )}
      >
        <Paneset>
          <SettingsComponent
            {...props}
          />
        </Paneset>
      </Pane>
    </Paneset>
  );
};

export default IllSystemSettings;
