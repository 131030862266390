export const INSTITUTION_CREATE = 'ill.institution.item.post';
export const INSTITUTION_DELETE = 'ill.institution.item.delete';
export const INSTITUTION_EDIT = 'ill.institution.item.put';
export const INSTITUTION_SEARCH = 'ill.institution.collection.get';

export const INSTITUTION_GROUP_CREATE = 'ill.institutiongroup.item.post';
export const INSTITUTION_GROUP_DELETE = 'ill.institutiongroup.item.delete';
export const INSTITUTION_GROUP_EDIT = 'ill.institutiongroup.item.put';
export const INSTITUTION_GROUP_SEARCH = 'ill.institutiongroup.collection.get';

export const INSTITUTION_USER_CREATE = 'ill.institutionuser.item.post';
export const INSTITUTION_USER_DELETE = 'ill.institutionuser.item.delete';
export const INSTITUTION_USER_EDIT = 'ill.institutionuser.item.put';
export const INSTITUTION_USER_MANAGE_INSTITUTION = 'ill.institutionuser.manageinstitution.get';
export const INSTITUTION_USER_SEARCH = 'ill.institutionuser.collection.get';

export const SHARED_INDEX_BY_QUERY = 'ill.sharedindexquery.query.get';

export const SYSTEM_SETTINGS_SEARCH = 'ill.systemsetting.index.get';

export const REQUEST_CREATE = 'ui-ill-request.create';
export const SUPPLY_CREATE = 'ui-ill-supply.create';
