/* eslint-disable react/jsx-fragments */
import {
  Fragment
} from 'react';

import {
  FormattedMessage
} from 'react-intl';

import {
  Card,
  Col,
  KeyValue,
  Row
} from '@folio/stripes/components';

import {
  useOkapiQuery
} from '@k-int/stripes-ill';

import {
  SHARED_INDEX_AVAILABILITY_ENDPOINT
} from '../../../constants/endpoints';

import DeterminedSuppliers from './DeterminedSuppliers';
import SharedIndexAvailability from './SharedIndexAvailability';
import StringList from './StringList';
import TypeValueList from './TypeValueList';

const SharedIndexRecord = ({ record }) => {
  const availabilityQuery = useOkapiQuery(SHARED_INDEX_AVAILABILITY_ENDPOINT, {
    searchParams: {
      id: record.bibClusterId
    }
  });

  return (
    <Fragment>
      <Row>
        <Col xs={12}>
          <KeyValue
            label={<FormattedMessage id="ui-ill-ui.sharedIndex.query.bibClusterId" />}
            value={record.bibClusterId}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <KeyValue
            label={<FormattedMessage id="ui-ill-ui.sharedIndex.query.title" />}
            value={record.title}
          />
        </Col>
      </Row>
      {record.primaryAuthor &&
        <Row>
          <Col xs={12}>
            <KeyValue
              label={<FormattedMessage id="ui-ill-ui.sharedIndex.record.primaryAuthor" />}
              value={record.primaryAuthor}
            />
          </Col>
        </Row>
      }
      {record.agents &&
        <Row>
          <Col xs={12}>
            <TypeValueList
              parentId={record.bibClusterId}
              listMsgId="agents"
              data={record.agents}
              typeField="subtype"
              valueField="label"
            />
          </Col>
        </Row>
      }
      {record.subjects &&
        <Row>
          <Col xs={12}>
            <TypeValueList
              parentId={record.bibClusterId}
              listMsgId="subjects"
              data={record.subjects}
              typeField="subtype"
              valueField="label"
            />
          </Col>
        </Row>
      }
      {record.identifiers &&
        <Row>
          <Col xs={12}>
            <TypeValueList
              parentId={record.bibClusterId}
              listMsgId="identifiers"
              data={record.identifiers}
              typeField="namespace"
              valueField="value"
            />
          </Col>
        </Row>
      }
      {record.isbn &&
        <Row>
          <Col xs={12}>
            <KeyValue
              label={<FormattedMessage id="ui-ill-ui.sharedIndex.record.isbn" />}
              value={record.isbn}
            />
          </Col>
        </Row>
      }
      {record.issn &&
        <Row>
          <Col xs={12}>
            <KeyValue
              label={<FormattedMessage id="ui-ill-ui.sharedIndex.record.issn" />}
              value={record.issn}
            />
          </Col>
        </Row>
      }
      {record.publisher &&
        <Row>
          <Col xs={12}>
            <KeyValue
              label={<FormattedMessage id="ui-ill-ui.sharedIndex.record.publisher" />}
              value={record.publisher}
            />
          </Col>
        </Row>
      }
      {record.dateOfPublication &&
        <Row>
          <Col xs={12}>
            <KeyValue
              label={<FormattedMessage id="ui-ill-ui.sharedIndex.record.dateOfPublication" />}
              value={record.dateOfPublication}
            />
          </Col>
        </Row>
      }
      {record.placeOfPublication &&
        <Row>
          <Col xs={12}>
            <KeyValue
              label={<FormattedMessage id="ui-ill-ui.sharedIndex.record.placeOfPublication" />}
              value={record.placeOfPublication}
            />
          </Col>
        </Row>
      }
      {record.yearOfPublication &&
        <Row>
          <Col xs={12}>
            <KeyValue
              label={<FormattedMessage id="ui-ill-ui.sharedIndex.record.yearOfPublication" />}
              value={record.yearOfPublication}
            />
          </Col>
        </Row>
      }
      {record.derivedType &&
        <Row>
          <Col xs={12}>
            <KeyValue
              label={<FormattedMessage id="ui-ill-ui.sharedIndex.record.derivedType" />}
              value={record.derivedType}
            />
          </Col>
        </Row>
      }
      {record.edition &&
        <Row>
          <Col xs={12}>
            <KeyValue
              label={<FormattedMessage id="ui-ill-ui.sharedIndex.record.edition" />}
              value={record.edition}
            />
          </Col>
        </Row>
      }
      {record.language &&
        <Row>
          <Col xs={12}>
            <StringList
              parentId={record.bibClusterId}
              listMsgId="language"
              data={record.language}
            />
          </Col>
        </Row>
      }
      {record.bibNotes &&
        <Row>
          <Col xs={12}>
            <StringList
              parentId={record.bibClusterId}
              listMsgId="bibNotes"
              data={record.bibNotes}
            />
          </Col>
        </Row>
      }
      {record.notes &&
        <Row>
          <Col xs={12}>
            <StringList
              parentId={record.bibClusterId}
              listMsgId="notes"
              data={record.notes}
            />
          </Col>
        </Row>
      }
      {record.physicalDescriptions &&
        <Row>
          <Col xs={12}>
            <StringList
              parentId={record.bibClusterId}
              listMsgId="physicalDescription"
              data={record.physicalDescriptions}
            />
          </Col>
        </Row>
      }
      {record.contents &&
        <Row>
          <Col xs={12}>
            <StringList
              parentId={record.bibClusterId}
              listMsgId="contents"
              data={record.contents}
            />
          </Col>
        </Row>
      }
      {record.contentType &&
        <Row>
          <Col xs={12}>
            <StringList
              parentId={record.bibClusterId}
              listMsgId="contentType"
              data={record.contentType}
            />
          </Col>
        </Row>
      }
      {record.mediaTypes &&
        <Row>
          <Col xs={12}>
            <StringList
              parentId={record.bibClusterId}
              listMsgId="mediaTypes"
              data={record.mediaTypes}
            />
          </Col>
        </Row>
      }
      {record.series &&
        <Row>
          <Col xs={12}>
            <StringList
              parentId={record.bibClusterId}
              listMsgId="series"
              data={record.series}
            />
          </Col>
        </Row>
      }
      {record.summary &&
        <Row>
          <Col xs={12}>
            <StringList
              parentId={record.bibClusterId}
              listMsgId="summary"
              data={record.summary}
            />
          </Col>
        </Row>
      }
      <Row>
        <Card
          headerStart={<FormattedMessage id="ui-ill-ui.sharedIndex.record.availability" />}
        >
          {availabilityQuery.isSuccess && availabilityQuery.data?.itemList &&
            availabilityQuery.data?.itemList.map((entry, i) => (
              <SharedIndexAvailability
                key={i}
                record={entry}
              />
            ))
          }
        </Card>
      </Row>
      <Row>
        <Card
          headerStart={<FormattedMessage id="ui-ill-ui.sharedIndex.record.determinedSuppliers" />}
        >
          <DeterminedSuppliers
            bibClusterId={record.bibClusterId}
          />
        </Card>
      </Row>
    </Fragment>
  );
};

export default SharedIndexRecord;
