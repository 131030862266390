import {
  FormattedMessage
} from 'react-intl';

import SharedIndexRecord from './SharedIndexRecord';
import SystemSearchAndSort from '../SystemSearchAndSort';

import {
  SYSTEM_AREA_INSTITUTION,
  SYSTEM_AREA_INSTITUTION_GROUP,
  SYSTEM_AREA_INSTITUTION_USER,
  SYSTEM_AREA_SETTINGS
} from '../SystemAreas';

import {
  SYSTEM_SEARCH_ARRAY_ALL
} from '../SystemSearchFields';

const SharedIndexQuery = ({ sharedIndexQuery, queryGetter, querySetter, children }) => {
  const getActionMenu = () => (
    ''
  );

  return (
    <SystemSearchAndSort
      area="sharedIndex"
      messageIdPrefix="sharedIndex.query"
      detailsTitleMessageId="sharedIndex.record"
      searchableIndexes={SYSTEM_SEARCH_ARRAY_ALL}
      actionsMenu={getActionMenu}
      visibleColumns={[
        'bibClusterId',
        'title'
      ]}
      columnMappings={{
        bibClusterId: <FormattedMessage id="ui-ill-ui.sharedIndex.query.bibClusterId" />,
        title: <FormattedMessage id="ui-ill-ui.sharedIndex.query.title" />
      }}
      formatter={{
      }}
      idField="bibClusterId"
      nameField="title"
      links={[
        SYSTEM_AREA_INSTITUTION,
        SYSTEM_AREA_INSTITUTION_GROUP,
        SYSTEM_AREA_INSTITUTION_USER,
        SYSTEM_AREA_SETTINGS
      ]}
      query={sharedIndexQuery}
      queryGetter={queryGetter}
      querySetter={querySetter}
      ViewPane={SharedIndexRecord}
      {...children}
    />
  );
};

export default SharedIndexQuery;
