import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import ResourceSharing from '@k-int/ill-ui';
import { NAMESPACE_ILL } from '@k-int/stripes-ill';

const ClearQueryCacheComponent = () => {
  const queryClient = useQueryClient();
  useEffect(() => {
    // The namespace ILL is added to the query name in Stripes-Ill useOkapiQuery.js
    queryClient.resetQueries({ queryKey: [ NAMESPACE_ILL ], exact: false });
  }, []);

  return null;
};

const RequestApp = (props) => <ResourceSharing {...props} />;

RequestApp.eventHandler = (event, _s, _data) => {
  switch (event) {
    // Ideally we would do this on LOGOUT, but that event does not get sent,
    // left it here in case they decide to send it in future
    // Ideally stripes would call queryClient.clear() on LOGOUT and therefore we wouldn't need to do this
    case 'LOGIN':
    case 'LOGOUT': {
      // Clear out the react Soquery caches
      return () => <ClearQueryCacheComponent />;
      break;
    }

    default:
      break;
  }

  return (null);
};

export default RequestApp;
//export default (props) => <ResourceSharing {...props} />;
