/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  FormattedMessage
} from 'react-intl';

import {
  Card
} from '@folio/stripes/components';

import css from './TypeValueList.css';

const TypeValueList = ({ parentId, listMsgId, data, typeField, valueField }) => {
  return (
    <Card
      id={`${parentId}-${listMsgId}-card`}
      headerStart={<FormattedMessage id={`ui-ill-ui.sharedIndex.record.${listMsgId}`} />}
      cardClass={css.typeValueListCard}
      headerClass={css.typeValueListCard}
      roundedBorder
    >
      <table className={css.typeValueList}>
        <thead>
          <tr>
            <th><FormattedMessage id="ui-ill-ui.sharedIndex.record.list.type" /></th>
            <th><FormattedMessage id="ui-ill-ui.sharedIndex.record.list.value" /></th>
          </tr>
        </thead>
        <tbody>
          {
            data.map((entry, i) => (
              <tr key={i}>
                <td>
                  {entry[typeField]}
                </td>
                <td>
                  {entry[valueField]}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </Card>
  );
};

export default TypeValueList;
