/* eslint-disable react/jsx-fragments */
import {
  useState,
  Fragment
} from 'react';

import {
  FormattedMessage
} from 'react-intl';

import {
  useQueryClient
} from 'react-query';

import {
  useLocation
} from 'react-router-dom';

import {
  PersistedPaneset,
  SearchAndSortQuery
} from '@folio/stripes/smart-components';

import queryString from 'query-string';

import {
  Button,
  LoadingPane,
  MultiColumnList,
  Pane,
  PaneHeader,
  PaneMenu,
} from '@folio/stripes/components';

import {
  AppIcon,
  IfPermission
} from '@folio/stripes/core';

import {
  NAMESPACE_ILL
} from '@k-int/stripes-ill';

import {
  STATUS_DETAILS_VIEW_CLOSED,
  STATUS_DETAILS_VIEW_CREATE,
  STATUS_DETAILS_VIEW_EDIT,
  STATUS_DETAILS_VIEW_OPEN
} from './DetailsViewConstants';

import DetailsView from './DetailsView';
import DetailsViewCreateEdit from './DetailsViewCreateEdit';
import SystemSearch from './SystemSearch';

const SystemSearchAndSort = ({
  area,
  messageIdPrefix,
  detailsTitleMessageId,
  searchableIndexes,
  actionsMenu,
  visibleColumns,
  columnMappings,
  formatter,
  idField,
  nameField,
  createPerm,
  deletePerm,
  editPerm,
  links,
  query,
  queryGetter,
  querySetter,
  ViewPane,
  areaCreateEditDetailsEndpoint,
  areaRecordEndpoint,
  areaEndpoint,
  FormComponent,
  onInitialiseValues,
  onBeforeSave,
  children
}) => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [detailsViewState, setDetailsViewState] = useState(STATUS_DETAILS_VIEW_CLOSED);

  const data = query?.data?.pages?.flatMap(x => x.results);
  const totalCount = query?.data?.pages?.[0]?.total;
  const parsedParams = queryString.parse(location.search);
  const sortOrder = parsedParams.sort || '';
  const fetchMore = (_askAmount, index) => {
    query.fetchNextPage({ pageParam: index });
  };

  const setViewStatus = (status) => {
    setDetailsViewState(status);
  };

  const setRecord = (record) => {
    setSelectedRecord(record);
  };

  const postSaveUpdate = (resetQuery) => {
    // Do we reset the query cache
    if (resetQuery || (selectedRecord !== null)) {
      // A Create, Delete or Edit operation has occureed so invalidate the cache
      queryClient.invalidateQueries({ queryKey: [NAMESPACE_ILL, areaEndpoint], exact: false });
    }
  };

  const isSelected = (item) => {
    // Is it selected
    return ((selectedRecord !== null) && (item.item[idField] === selectedRecord[idField]));
  };

  return (
    <SearchAndSortQuery
      initialSearch="?sort=name"
      initialSearchState={{ query: '' }}
      key={location.search}
      queryGetter={queryGetter}
      querySetter={querySetter}
    >
      {
        ({
          _activeFilters,
          _filterChanged,
          _getFilterHandlers,
          getSearchHandlers,
          onSort,
          onSubmitSearch,
          resetAll,
          searchChanged,
          searchValue,
        }) => (
          <div>
            <PaneHeader
              paneTitle={<FormattedMessage id="ui-ill-ui.system.management" />}
              lastMenu={(
                <PaneMenu>
                  <Fragment>
                    {
                      links.map((entry, i) => (
                        <IfPermission key={i} perm={entry.permission}>
                          <Button
                            buttonStyle="primary"
                            id={`clickable-ill-${area}-${entry.link}`}
                            marginBottom0
                            to={`${entry.link}`}
                          >
                            <FormattedMessage id={`ui-ill-ui.${entry.linkMessageId}`} />
                          </Button>
                        </IfPermission>
                      ))
                    }
                  </Fragment>
                </PaneMenu>
              )}
            />
            <PersistedPaneset
              appId={`@k-int/${area}`}
              id={`paneset-${area}`}
            >
              <Pane
                defaultWidth="20%"
                paneTitle={<FormattedMessage id="stripes-smart-components.searchAndFilter" />}
              >
                <form onSubmit={onSubmitSearch}>
                  <SystemSearch
                    searchChanged={searchChanged}
                    searchHandlers={getSearchHandlers()}
                    searchValue={searchValue}
                    resetAll={resetAll}
                    searchableIndexes={searchableIndexes}
                  />
                </form>
              </Pane>
              {query.isSuccess ?
                <Pane
                  actionMenu={actionsMenu}
                  appIcon={<AppIcon app="Requests" iconKey="app" size="small" />}
                  defaultWidth="fill"
                  lastMenu={(
                    <PaneMenu>
                      {createPerm &&
                        <IfPermission perm={createPerm}>
                          <Button
                            buttonStyle="primary"
                            id={`clickable-ill-${area}.create`}
                            marginBottom0
                            onClick={() => {
                              setRecord(null);
                              setViewStatus(STATUS_DETAILS_VIEW_CREATE);
                            }}
                          >
                            <FormattedMessage id="ui-ill-ui.create" />
                          </Button>
                        </IfPermission>
                      }
                    </PaneMenu>
                  )}
                  noOverflow
                  padContent={false}
                  paneSub={query?.isSuccess ?
                    <FormattedMessage
                      id={`ui-ill-ui.${messageIdPrefix}s.found`}
                      values={{ number: totalCount }}
                    /> : ''}
                  paneTitle={<FormattedMessage id={`ui-ill-ui.${messageIdPrefix}s`} />}
                >
                  <MultiColumnList
                    // onRowClick={(_e, record) => history.push(`./${area}/${record.id}`)}
                    onRowClick={(_e, record) => {
                      setRecord(record);
                      setDetailsViewState(STATUS_DETAILS_VIEW_OPEN);
                    }}
                    showSingleResult
                    autosize
                    columnMapping={columnMappings}
                    columnWidths={{
                    }}
                    contentData={data}
                    formatter={formatter}
                    hasMargin
                    isEmptyMessage={
                      <>
                        <FormattedMessage id={`ui-ill-ui.${messageIdPrefix}s.notFound`} /><br />
                      </>
                    }
                    isSelected={isSelected}
                    loading={query?.isFetching}
                    onHeaderClick={onSort}
                    onNeedMoreData={fetchMore}
                    sortOrder={sortOrder.replace(/^-/, '').replace(/,.*/, '')}
                    sortDirection={sortOrder.startsWith('-') ? 'descending' : 'ascending'}
                    totalCount={totalCount}
                    virtualize
                    visibleColumns={visibleColumns}
                  />
                </Pane>
                : <LoadingPane />
              }
              {((detailsViewState === STATUS_DETAILS_VIEW_CLOSED) ||
                ((selectedRecord === null) &&
                (detailsViewState !== STATUS_DETAILS_VIEW_CREATE))) ?
                '' :
                ((detailsViewState === STATUS_DETAILS_VIEW_CREATE) ||
                  (detailsViewState === STATUS_DETAILS_VIEW_EDIT)) ? (
                    <DetailsViewCreateEdit
                      messageIdPrefix={messageIdPrefix}
                      recordIdentifier={selectedRecord?.id}
                      areaCreateEditDetailsEndpoint={areaCreateEditDetailsEndpoint}
                      areaRecordEndpoint={areaRecordEndpoint}
                      areaEndpoint={areaEndpoint}
                      FormComponent={FormComponent}
                      onInitialiseValues={onInitialiseValues}
                      onBeforeSave={onBeforeSave}
                      postSaveUpdate={postSaveUpdate}
                      setViewStatus={setViewStatus}
                    />
                  ) : (
                    <DetailsView
                      record={selectedRecord}
                      area={area}
                      messageIdPrefix={messageIdPrefix}
                      detailsTitleMessageId={detailsTitleMessageId}
                      nameField={nameField}
                      setDetailsViewStatus={setViewStatus}
                      ViewPane={ViewPane}
                      deletePerm={deletePerm}
                      editPerm={editPerm}
                      areaRecordEndpoint={areaRecordEndpoint}
                      postDelete={postSaveUpdate}
                    />
                  )
              }
              {children}
            </PersistedPaneset>
          </div>
        )
      }
    </SearchAndSortQuery>
  );
};

export default SystemSearchAndSort;
