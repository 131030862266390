import GenericInfo from './GenericInfo';
import css from './UserInfo.css';

const GroupUserInfo = ({ users }) => {
  return (
    <GenericInfo
      titleId="institution.users"
      list={users}
      linkPath="institutionUser"
      cssCard={css.userCard}
      cssCardHeader={css.userCardHeader}
      cssTable={css.userEntryList}
      descriptionProperty="folioUserId"
      descriptionId="institution.user.folioUserId"
    />
  );
};

export default GroupUserInfo;
