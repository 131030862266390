/* eslint-disable react/jsx-fragments */
import { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field } from 'react-final-form';
import { Col, Row, MultiSelection, Select, TextField, TextArea } from '@folio/stripes/components';
import { required } from '@folio/stripes/util';

const InstitutionForm = ({ createEditDetails, _onSISelect }) => {
  const intl = useIntl();
  return (
    <Fragment>
      <Row>
        <Col xs={12}>
          <Field
            id="edit-institution-name"
            name="name"
            label={<FormattedMessage id="ui-ill-ui.institution.name" />}
            component={TextField}
            required
            validate={required}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            id="edit-institution-description"
            name="description"
            label={<FormattedMessage id="ui-ill-ui.institution.description" />}
            component={TextArea}
            required
            validate={required}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            id="edit-institution-directoryEntry"
            name="directoryEntry.id"
            label={<FormattedMessage id="ui-ill-ui.institution.directoryEntry" />}
            placeholder={intl.formatMessage({ id: 'ui-ill-ui.institution.directoryEntry.select.placeHolder' })}
            component={Select}
            dataOptions={createEditDetails.directoryEntries}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            id="edit-institution-groups"
            name="tGroups"
            label={<FormattedMessage id="ui-ill-ui.institution.groups" />}
            placeholder={intl.formatMessage({ id: 'ui-ill-ui.institution.groups.select.placeHolder' })}
            component={MultiSelection}
            dataOptions={createEditDetails.groups}
            onBlur={e => { e.preventDefault(); }}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default InstitutionForm;
