/* eslint-disable import/prefer-default-export */
import {
  FormattedMessage
} from 'react-intl';

import {
  INSTITUTION_CREATE,
  INSTITUTION_DELETE,
  INSTITUTION_EDIT
} from '../../../constants/permissions';

import {
  INSTITUTION_ENDPOINT,
  INSTITUTION_WITH_SEPARATOR_ENDPOINT,
  INSTITUTION_CREATE_EDIT_DETAILS_ENDPOINT
} from '../../../constants/endpoints';

import {
  SYSTEM_AREA_INSTITUTION_GROUP,
  SYSTEM_AREA_INSTITUTION_USER,
  SYSTEM_AREA_SHARED_INDEX_QUERY,
  SYSTEM_AREA_SETTINGS
} from '../SystemAreas';

import {
  SYSTEM_SEARCH_ARRAY_ALL_NAME_DESCRIPTION
} from '../SystemSearchFields';

import SystemSearchAndSort from '../SystemSearchAndSort';
import Institution from './Institution';
import InstitutionForm from './InstitutionForm';

const Institutions = ({ institutionsQuery, queryGetter, querySetter, children }) => {
  const getActionMenu = () => (
    ''
  );

  const onIninitialiseValues = (record) => {
    let initialValues;
    if (record === null) {
      initialValues = {
        tGroups: []
      };
    } else {
      initialValues = {
        ...record,
        tGroups: record.institutionGroups.map((group) => {
          return ({ label: group.name, value: group.id });
        })
      };
    }
    return (initialValues);
  };

  const onBeforeSave = (record) => {
    // Setup the groups correctly
    record.institutionGroups = record.tGroups.map((group) => {
      return ({ id: group.value });
    });
  };

  return (
    <SystemSearchAndSort
      area="institution"
      messageIdPrefix="institution"
      detailsTitleMessageId="institution"
      searchableIndexes={SYSTEM_SEARCH_ARRAY_ALL_NAME_DESCRIPTION}
      actionsMenu={getActionMenu}
      visibleColumns={[
        'name',
        'description',
        'directoryEntry'
      ]}
      columnMappings={{
        name: <FormattedMessage id="ui-ill-ui.institutions.name" />,
        description: <FormattedMessage id="ui-ill-ui.institutions.description" />,
        directoryEntry: <FormattedMessage id="ui-ill-ui.institutions.directoryEntry" />,
      }}
      formatter={{
        directoryEntry: institution => {
          return institution.directoryEntry?.name;
        }
      }}
      idField="id"
      nameField="name"
      createPerm={INSTITUTION_CREATE}
      deletePerm={INSTITUTION_DELETE}
      editPerm={INSTITUTION_EDIT}
      links={[
        SYSTEM_AREA_INSTITUTION_GROUP,
        SYSTEM_AREA_INSTITUTION_USER,
        SYSTEM_AREA_SHARED_INDEX_QUERY,
        SYSTEM_AREA_SETTINGS
      ]}
      query={institutionsQuery}
      queryGetter={queryGetter}
      querySetter={querySetter}
      ViewPane={Institution}
      areaCreateEditDetailsEndpoint={INSTITUTION_CREATE_EDIT_DETAILS_ENDPOINT}
      areaRecordEndpoint={INSTITUTION_WITH_SEPARATOR_ENDPOINT}
      areaEndpoint={INSTITUTION_ENDPOINT}
      FormComponent={InstitutionForm}
      onInitialiseValues={onIninitialiseValues}
      onBeforeSave={onBeforeSave}
      {...children}
    />
  );
};

export { Institutions };
