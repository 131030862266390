import {
  useInfiniteQuery
} from 'react-query';

import {
  useOkapiKy
} from '@folio/stripes/core';

import {
  generateKiwtQuery,
  useKiwtSASQuery
} from '@k-int/stripes-kint-components';

import {
  NAMESPACE_ILL
} from '@k-int/stripes-ill';

import SharedIndexQuery from '../../../components/System/SharedIndex/SharedIndexQuery';

import {
  SHARED_INDEX_QUERY_ENDPOINT
} from '../../../constants/endpoints';

const PER_PAGE = 100;

const SharedIndexQueryRoute = ({ _appName, children }) => {
  const { query, queryGetter, querySetter } = useKiwtSASQuery();
  const ky = useOkapiKy();

  const SASQ_MAP = {
    searchKey: 'All',
    sortKeys: {
    },
    perPage: PER_PAGE
  };

  const sharedIndexQuery = useInfiniteQuery(
    {
      queryKey: [NAMESPACE_ILL, SHARED_INDEX_QUERY_ENDPOINT, query, '@k-int/sharedIndex'],
      queryFn: ({ pageParam = 0 }) => ky(`${SHARED_INDEX_QUERY_ENDPOINT}${generateKiwtQuery({ offset: pageParam, ...SASQ_MAP }, query)}`).json(),
      useErrorBoundary: true,
      staleTime: 2 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      // we render before useKiwtSASQuery() finishes, let's prevent an extra, unnecessary, fetch
      // enabled: Object.prototype.hasOwnProperty.call(query, 'query'),
    }
  );

  return (
    <SharedIndexQuery
      sharedIndexQuery={sharedIndexQuery}
      queryGetter={queryGetter}
      querySetter={querySetter}
    >
      {children}
    </SharedIndexQuery>
  );
};

export default SharedIndexQueryRoute;
