import React, { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useIntl } from 'react-intl';
import { useOkapiKy } from '@folio/stripes/core';
import { generateKiwtQuery, useKiwtSASQuery } from '@k-int/stripes-kint-components';
import { useOkapiQuery, NAMESPACE_ILL } from '@k-int/stripes-ill';
import PatronRequests from '../components/PatronRequests';

import {
  BATCH_ENDPOINT,
  DIRECTORY_ENTRY_ENDPOINT,
  HOST_LMS_LOCATIONS_ENDPOINT,
  PATRON_REQUESTS_ENDPOINT,
  SHELVING_LOCATIONS_ENDPOINT
} from '../constants/endpoints';

const PER_PAGE = 100;

const compareLabel = (a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0);
const compareCreated = (a, b) => (new Date(b?.dateCreated) - new Date(a?.dateCreated));

const PatronRequestsRoute = ({ appName, children }) => {
  const intl = useIntl();
  const { query, queryGetter, querySetter } = useKiwtSASQuery();
  const ky = useOkapiKy();

  const SASQ_MAP = {
    searchKey: 'id,hrid,patronGivenName,patronSurname,title,author,issn,isbn,volumes.itemId',
    // Omitting the date and unread filter keys here causes it to include their value verbatim
    // rather than adding the key name and operator. This way we can store the operator and field
    // in the value eg. how the hasUnread checkbox sets a value of 'unreadMessageCount>0'.
    filterKeys: {
      'batch': 'batches.id',
      'r': 'isRequester',
      'needsAttention': 'state.needsAttention',
      'state': 'state.code',
      'location': 'pickLocation.id',
      'requester': 'resolvedRequester.owner.id',
      'shelvingLocation': 'pickShelvingLocation.id',
      'supplier': 'resolvedSupplier.owner.id',
      'terminal': 'state.terminal'
    },
    sortKeys: {
      'pickLocation': 'pickLocation.name',
      'pickShelvingLocation': 'pickShelvingLocation.name',
      'supplyingInstitutionSymbol': 'resolvedSupplier.symbol',
    },
    // Extra keys in the object are added to mod-ill params by generateKiwtQuery
    perPage: PER_PAGE,
    filters: [{
      path: 'isRequester',
      value: appName === 'request' ? 'true' : 'false'
    }],
  };

  const states = useMemo(() => {
    const statePrefix = appName === 'supply' ? 'RES' : 'REQ';
    const keys = Object.keys(intl.messages).filter(
      key => key.startsWith(`stripes-ill.states.${statePrefix}_`)
    );
    return keys
      .map(key => ({ label: intl.messages[key], value: key.replace('stripes-ill.states.', '') }))
      .sort(compareLabel);
  }, [appName, intl]);

  const prQuery = useInfiniteQuery(
    {
      queryKey: [NAMESPACE_ILL, PATRON_REQUESTS_ENDPOINT, query, `@k-int/${appName}`],
      queryFn: ({ pageParam = 0 }) => ky(`${PATRON_REQUESTS_ENDPOINT}${generateKiwtQuery({ offset: pageParam, ...SASQ_MAP }, query)}`).json(),
      useErrorBoundary: true,
      staleTime: 2 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      // we render before useKiwtSASQuery() finishes, let's prevent an extra, unnecessary, fetch
      enabled: Object.prototype.hasOwnProperty.call(query, 'query'),
    }
  );

  const filterQueries = [
    useOkapiQuery(BATCH_ENDPOINT, {
      searchParams: {
        perPage: '1000',
        filters: appName === 'supply' ? 'isRequester!=true' : 'isRequester==true',
      },
      staleTime: 15 * 60 * 1000
    }),
    useOkapiQuery(HOST_LMS_LOCATIONS_ENDPOINT, { searchParams: { perPage: '1000' }, staleTime: 2 * 60 * 60 * 1000 }),
    useOkapiQuery(SHELVING_LOCATIONS_ENDPOINT, { searchParams: { perPage: '1000' }, staleTime: 2 * 60 * 60 * 1000 }),
    useOkapiQuery(DIRECTORY_ENTRY_ENDPOINT, {
      searchParams: {
        filters: 'type.value=institution',
        perPage: '1000',
        stats: 'true',
      },
      staleTime: 2 * 60 * 60 * 1000
    }),
  ];

  let filterOptions;
  if (filterQueries.every(x => x.isSuccess)) {
    const [batches, lmsLocations, shelvingLocations, { results: institutions }] = filterQueries.map(x => x.data);
    filterOptions = {
      batch: batches
        .sort(compareCreated)
        .map(x => ({ label: x.description, value: x.id, dateCreated: x.dateCreated })),
      hasUnread: [({ label: intl.formatMessage({ id: 'ui-ill-ui.unread' }), value: 'hasUnreadMessages=true' })],
      institution: institutions
        .map(x => ({ label: x.name, value: x.id }))
        .sort(compareLabel),
      location: lmsLocations
        .map(x => ({ label: x.name, value: x.id }))
        .sort(compareLabel),
      needsAttention: [({ label: intl.formatMessage({ id: 'ui-ill-ui.needsAttention' }), value: 'true' })],
      shelvingLocation: shelvingLocations
        .map(x => ({ label: x.name, value: x.id }))
        .sort(compareLabel),
      state: states,
      terminal: [({ label: intl.formatMessage({ id: 'ui-ill-ui.hideComplete' }), value: 'false' })],
    };
  }

  return (
    <PatronRequests
      requestsQuery={prQuery}
      queryGetter={queryGetter}
      querySetter={querySetter}
      filterOptions={filterOptions}
      searchParams={generateKiwtQuery(SASQ_MAP, query)}
    >
      {children}
    </PatronRequests>
  );
};

export default PatronRequestsRoute;
