/* eslint-disable react/jsx-fragments */
import {
  Fragment
} from 'react';

import {
  FormattedMessage,
  useIntl
} from 'react-intl';

import {
  Field
} from 'react-final-form';

import {
  Col,
  MultiSelection,
  Row,
  TextArea,
  TextField
} from '@folio/stripes/components';

import {
  required
} from '@folio/stripes/util';

const InstitutionGroupForm = ({ createEditDetails, _onSISelect }) => {
  const intl = useIntl();
  return (
    <Fragment>
      <Row>
        <Col xs={12}>
          <Field
            id="edit-institutionGroup-name"
            name="name"
            label={<FormattedMessage id="ui-ill-ui.institution.group.name" />}
            component={TextField}
            required
            validate={required}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            id="edit-institutionGroup-description"
            name="description"
            label={<FormattedMessage id="ui-ill-ui.institution.group.description" />}
            component={TextArea}
            required
            validate={required}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            id="edit-institutionGroup-institutions"
            name="tInstitutions"
            label={<FormattedMessage id="ui-ill-ui.institution.group.institutions" />}
            placeholder={intl.formatMessage({ id: 'ui-ill-ui.institution.group.institutions.select.placeHolder' })}
            component={MultiSelection}
            dataOptions={createEditDetails.institutions}
            onBlur={e => { e.preventDefault(); }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            id="edit-institutionGroup-users"
            name="tUsers"
            label={<FormattedMessage id="ui-ill-ui.institution.group.users" />}
            placeholder={intl.formatMessage({ id: 'ui-ill-ui.institution.group.users.select.placeHolder' })}
            component={MultiSelection}
            dataOptions={createEditDetails.users}
            onBlur={e => { e.preventDefault(); }}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default InstitutionGroupForm;
