// TODO I find this really difficult to read, refactor some of the base names and organise this lot
// I think ENDPOINT_SEPARATOR is largely useless... endpoints are endpoints,
// we're not going to change the routing wholesale. However it's used elsewhere (directory)

export const ENDPOINT_SEPARATOR = '/';
export const ILL_ENDPOINT = `ill${ENDPOINT_SEPARATOR}`;

export const BATCH_ENDPOINT = `${ILL_ENDPOINT}batch`;
export const CUSTOM_PROPERTIES_ENDPOINT = `${ILL_ENDPOINT}custprops`;

export const DIRECTORY_ENDPOINT = `${ILL_ENDPOINT}directory`;
export const DIRECTORY_WITH_SEPARATOR_ENDPOINT = `${DIRECTORY_ENDPOINT}${ENDPOINT_SEPARATOR}`;
export const DIRECTORY_ENTRIES_ENDPOINT = `${DIRECTORY_WITH_SEPARATOR_ENDPOINT}entry`;
export const DIRECTORY_ENTRIES_WITH_SEPARATOR_ENDPOINT = `${DIRECTORY_ENTRIES_ENDPOINT}${ENDPOINT_SEPARATOR}`;
export const DIRECTORY_ENTRIES_VIEW_ENDPOINT = `${DIRECTORY_ENTRIES_WITH_SEPARATOR_ENDPOINT}view`;
export const DIRECTORY_ENTRIES_VIEW_WITH_SEPARATOR_ENDPOINT = `${DIRECTORY_ENTRIES_VIEW_ENDPOINT}${ENDPOINT_SEPARATOR}`;
export const DIRECTORY_ENTRY_ENDPOINT = `${DIRECTORY_WITH_SEPARATOR_ENDPOINT}entry`;
export const DIRECTORY_ENTRY_WITH_SEPARATOR_ENDPOINT = `${DIRECTORY_ENTRY_ENDPOINT}${ENDPOINT_SEPARATOR}`;
export const DIRECTORY_ENTRY_VALIDATE_ENDPOINT = `${DIRECTORY_ENTRY_WITH_SEPARATOR_ENDPOINT}validate`;
export const DIRECTORY_NAMING_AUTHORITY_ENDPOINT = `${DIRECTORY_WITH_SEPARATOR_ENDPOINT}namingAuthority`;
export const DIRECTORY_SERVICE_ENDPOINT = `${DIRECTORY_WITH_SEPARATOR_ENDPOINT}service`;

export const FILE_DEFINITION_ENDPOINT = `${ILL_ENDPOINT}fileDefinition`;
export const FILE_DEFINITION_WITH_SEPARATOR_ENDPOINT = `${FILE_DEFINITION_ENDPOINT}${ENDPOINT_SEPARATOR}`;
export const FILE_DEFINITION_FILE_UPLOAD_ENDPOINT = `${FILE_DEFINITION_WITH_SEPARATOR_ENDPOINT}fileUpload`;

export const HOST_LMS_LOAN_POLICIES_ENDPOINT = `${ILL_ENDPOINT}hostLMSItemLoanPolicy`;
export const HOST_LMS_LOAN_POLICIES_WITH_SEPARATOR_ENDPOINT = `${HOST_LMS_LOAN_POLICIES_ENDPOINT}${ENDPOINT_SEPARATOR}`;

export const HOST_LMS_LOCATIONS_ENDPOINT = `${ILL_ENDPOINT}hostLMSLocations`;

export const HOST_LMS_PATRON_PROFILES_ENDPOINT = `${ILL_ENDPOINT}hostLMSPatronProfiles`;

export const HOST_LMS_PATRON_PROFILES_WITH_SEPARATOR_ENDPOINT = `${HOST_LMS_PATRON_PROFILES_ENDPOINT}${ENDPOINT_SEPARATOR}`;
export const INSTITUTION_ENDPOINT = `${ILL_ENDPOINT}institution`;
export const INSTITUTION_WITH_SEPARATOR_ENDPOINT = `${INSTITUTION_ENDPOINT}${ENDPOINT_SEPARATOR}`;
export const INSTITUTION_CREATE_EDIT_DETAILS_ENDPOINT = `${INSTITUTION_WITH_SEPARATOR_ENDPOINT}createEditDetails`;
export const INSTITUTION_GROUP_ENDPOINT = `${ILL_ENDPOINT}institutionGroup`;
export const INSTITUTION_GROUP_WITH_SEPARATOR_ENDPOINT = `${INSTITUTION_GROUP_ENDPOINT}${ENDPOINT_SEPARATOR}`;
export const INSTITUTION_GROUP_CREATE_EDIT_DETAILS_ENDPOINT = `${INSTITUTION_GROUP_WITH_SEPARATOR_ENDPOINT}createEditDetails`;
export const INSTITUTION_USER_ENDPOINT = `${ILL_ENDPOINT}institutionUser`;
export const INSTITUTION_USER_WITH_SEPARATOR_ENDPOINT = `${INSTITUTION_USER_ENDPOINT}${ENDPOINT_SEPARATOR}`;
export const INSTITUTION_USER_CREATE_EDIT_DETAILS_ENDPOINT = `${INSTITUTION_USER_WITH_SEPARATOR_ENDPOINT}createEditDetails`;
export const INSTITUTION_USER_CAN_MANAGE_ENDPOINT = `${INSTITUTION_USER_WITH_SEPARATOR_ENDPOINT}canManage`;
export const INSTITUTION_USER_MANAGE_INSTITUTION_ENDPOINT = `${INSTITUTION_USER_WITH_SEPARATOR_ENDPOINT}manageInstitution`;
export const NOTICE_POLICIES_ENDPOINT = `${ILL_ENDPOINT}noticePolicies`;
export const REFDATA_ENDPOINT = `${ILL_ENDPOINT}refdata`;
export const REPORT_ENDPOINT = `${ILL_ENDPOINT}report`;
export const REPORT_WITH_SEPARATOR_ENDPOINT = `${REPORT_ENDPOINT}${ENDPOINT_SEPARATOR}`;
export const REPORT_CREATE_UPDATE_ENDPOINT = `${REPORT_WITH_SEPARATOR_ENDPOINT}createUpdate`;
export const REPORT_GENERATE_PICKLIST_ENDPOINT = `${REPORT_WITH_SEPARATOR_ENDPOINT}generatePicklist`;


export const SETTINGS_WITH_SEPARATOR_ENDPOINT = `${ILL_ENDPOINT}settings${ENDPOINT_SEPARATOR}`;
export const SETTINGS_ENDPOINT = `${SETTINGS_WITH_SEPARATOR_ENDPOINT}appSettings`;
export const SETTINGS_INSTITUTION_ENDPOINT = `${SETTINGS_WITH_SEPARATOR_ENDPOINT}institutionSetting`;
export const SETTINGS_SYSTEM_ENDPOINT = `${SETTINGS_WITH_SEPARATOR_ENDPOINT}systemSetting`;

export const SHARED_INDEX_WITH_SEPARATOR_ENDPOINT = `${ILL_ENDPOINT}sharedIndexQuery${ENDPOINT_SEPARATOR}`;
export const SHARED_INDEX_AVAILABILITY_ENDPOINT = `${SHARED_INDEX_WITH_SEPARATOR_ENDPOINT}availability`;
export const SHARED_INDEX_FIND_MORE_SUPPLIERS_ENDPOINT = `${SHARED_INDEX_WITH_SEPARATOR_ENDPOINT}findMoreSuppliers`;
export const SHARED_INDEX_QUERY_ENDPOINT = `${SHARED_INDEX_WITH_SEPARATOR_ENDPOINT}byQuery`;
export const SHELVING_LOCATIONS_ENDPOINT = `${ILL_ENDPOINT}shelvingLocations`;
export const SHELVING_LOCATIONS_WITH_SEPARATOR_ENDPOINT = `${SHELVING_LOCATIONS_ENDPOINT}${ENDPOINT_SEPARATOR}`;
export const SHELVING_LOCATION_SITES_ENDPOINT = `${ILL_ENDPOINT}shelvingLocationSites`;
export const SHELVING_LOCATION_SITES_WITH_SEPARATOR_ENDPOINT = `${SHELVING_LOCATION_SITES_ENDPOINT}${ENDPOINT_SEPARATOR}`;
export const TEMPLATES_ENDPOINT = `${ILL_ENDPOINT}template`;
export const TAGS_ENDPOINT = `${ILL_ENDPOINT}tags`;
export const TIMERS_ENDPOINT = `${ILL_ENDPOINT}timers`;
export const TIMERS_WITH_SEPARATOR_ENDPOINT = `${TIMERS_ENDPOINT}${ENDPOINT_SEPARATOR}`;

export const PATRON_REQUESTS_ENDPOINT = `${ILL_ENDPOINT}patronrequests`;
export const PATRON_REQUESTS_WITH_SEPARATOR_ENDPOINT = `${PATRON_REQUESTS_ENDPOINT}${ENDPOINT_SEPARATOR}`;
export const PATRON_REQUESTS_GENERATE_PICKLIST_BATCH_ENDPOINT = `${PATRON_REQUESTS_WITH_SEPARATOR_ENDPOINT}generatePickListBatch`;
export const PATRON_REQUESTS_MARK_BATCH_AS_PRINTED_ENDPOINT = `${PATRON_REQUESTS_WITH_SEPARATOR_ENDPOINT}markBatchAsPrinted`;
export const PATRON_REQUESTS_NEW_REQUEST_DETAILS_ENDPOINT = `${PATRON_REQUESTS_WITH_SEPARATOR_ENDPOINT}newRequestDetails`;

export const PATRON_REQUESTS_MANUAL_CLOSE_STATES_ENDPOINT = `${ENDPOINT_SEPARATOR}manualCloseStates`;
