/* eslint-disable react/jsx-fragments */
import {
  Fragment
} from 'react';

import {
  FormattedMessage
} from 'react-intl';

import {
  Col,
  KeyValue,
  Row
} from '@folio/stripes/components';

import {
  useOkapiQuery
} from '@k-int/stripes-ill';

import {
  INSTITUTION_WITH_SEPARATOR_ENDPOINT,
  INSTITUTION_USER_WITH_SEPARATOR_ENDPOINT
} from '../../../constants/endpoints';

import GroupInfo from './sections/GroupInfo';
import InstitutionInfo from './sections/InstitutionInfo';

const InstitutionUser = ({ id }) => {
  const institutionUserQuery = useOkapiQuery(`${INSTITUTION_USER_WITH_SEPARATOR_ENDPOINT}${id}`);
  const record = institutionUserQuery.data;
  const institutionManagingId = record?.institutionManaging?.id;
  const institutionManagingQuery = useOkapiQuery(`${INSTITUTION_WITH_SEPARATOR_ENDPOINT}${institutionManagingId}`, { enabled: !!institutionManagingId });
  const recordManagingInstitution = institutionManagingQuery.data;

  // If query not a success just return
  if (!institutionUserQuery.isSuccess) {
    return null;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={12}>
          <KeyValue
            label={<FormattedMessage id="ui-ill-ui.institution.user.name" />}
            value={record.name}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <KeyValue
            label={<FormattedMessage id="ui-ill-ui.institution.user.managing" />}
            value={recordManagingInstitution?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <KeyValue
            label={<FormattedMessage id="ui-ill-ui.institution.user.folioUserId" />}
            value={record.folioUserId}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <GroupInfo
            groups={record.institutionGroups}
            parentId={record.id}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <InstitutionInfo
            parentId={record.id}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default InstitutionUser;
