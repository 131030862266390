import {
  INSTITUTION_GROUP_SEARCH,
  INSTITUTION_SEARCH,
  INSTITUTION_USER_SEARCH,
  SHARED_INDEX_BY_QUERY,
  SYSTEM_SETTINGS_SEARCH
} from '../../constants/permissions';

export const SYSTEM_AREA_INSTITUTION =
{
  permission: INSTITUTION_SEARCH,
  link: 'institutions',
  linkMessageId: 'institutions'
};

export const SYSTEM_AREA_INSTITUTION_GROUP =
{
  permission: INSTITUTION_GROUP_SEARCH,
  link: 'institutionGroups',
  linkMessageId: 'institution.groups'
};

export const SYSTEM_AREA_INSTITUTION_USER =
{
  permission: INSTITUTION_USER_SEARCH,
  link: 'institutionUsers',
  linkMessageId: 'institution.users'
};

export const SYSTEM_AREA_SHARED_INDEX_QUERY =
{
  permission: SHARED_INDEX_BY_QUERY,
  link: 'sharedIndexQuery',
  linkMessageId: 'sharedIndex.query'
};

export const SYSTEM_AREA_SETTINGS =
{
  permission: SYSTEM_SETTINGS_SEARCH,
  link: 'illSystemSettings',
  linkMessageId: 'system.settings'
};
