/* eslint-disable jsx-a11y/control-has-associated-label */
// (it doesn't recognise FormattedMessage as a text label for th)
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Card } from '@folio/stripes/components';
import formattedDateTime from '../../../util/formattedDateTime';
import css from './ProtocolInfo.css';

const ProtocolInfo = ({ record, id }) => {
  const protocolMessages = (record || {}).protocolAudit || [];

  return (
    <Card
      id={`${id}-protocol-card`}
      headerStart="Reverse chronological"
      roundedBorder
      cardClass={css.protocolCard}
      headerClass={css.protocolCardHeader}
    >
      <table className={css.protocolEntryList}>
        <thead>
          <tr>
            <th>#</th>
            <th><FormattedMessage id="ui-ill-ui.protocol.date" /></th>
            <th><FormattedMessage id="ui-ill-ui.protocol.duration" /></th>
            <th><FormattedMessage id="ui-ill-ui.protocol.protocol" /></th>
            <th><FormattedMessage id="ui-ill-ui.protocol.url" /></th>
          </tr>
        </thead>
        <tbody>
          {
            protocolMessages.map((entry, i) => (
              <Fragment key={i}>
                <tr key={`${entry.id}-1`}>
                  <td>{protocolMessages.length - i}</td>
                  <td>{formattedDateTime(entry.dateCreated)}</td>
                  <td>{entry.duration}</td>
                  <td>{entry.protocolType}</td>
                  <td>{entry.url}</td>
                </tr>
                <tr key={`${entry.id}-2`}>
                  <td />
                  <td><FormattedMessage id="ui-ill-ui.protocol.request" /></td>
                  <td colSpan="3">{entry.requestBody}</td>
                </tr>
                <tr key={`${entry.id}-3`}>
                  <td />
                  <td><FormattedMessage id="ui-ill-ui.protocol.response" /></td>
                  <td colSpan="3">{entry.responseBody}</td>
                </tr>
              </Fragment>
            ))
          }
        </tbody>
      </table>
    </Card>
  );
};

export default ProtocolInfo;
