import React, {
  useContext
} from 'react';

import {
  FormattedMessage
} from 'react-intl';

import {
  Form
} from 'react-final-form';

import {
  useMutation,
  useQueryClient
} from 'react-query';

import {
  Prompt
} from 'react-router-dom';

import {
  Button,
  Card,
  KeyValue,
  Pane,
  PaneMenu
} from '@folio/stripes/components';

import {
  CalloutContext,
  useOkapiKy
} from '@folio/stripes/core';

import {
  NAMESPACE_ILL,
  useOkapiQuery
} from '@k-int/stripes-ill';

import {
  PANE_WIDTH_DEFAULT,
  STATUS_DETAILS_VIEW_CLOSED,
  STATUS_DETAILS_VIEW_OPEN
} from './DetailsViewConstants';

import css from './DetailsViewCreateEdit.css';

const MODIFY_STATUS_NONE = 0;
const MODIY_STATUS_CREATED = 1;
const MODIFY_STATUS_UPDATED = 2;

const handleSISelect = (args, state, tools) => {
  Object.entries(args[0]).forEach(([field, value]) => tools.changeValue(state, field, () => value));
};

const DetailsViewCreateEdit = ({
  messageIdPrefix,
  recordIdentifier,
  areaCreateEditDetailsEndpoint,
  areaRecordEndpoint,
  areaEndpoint,
  FormComponent,
  onInitialiseValues,
  onBeforeSave,
  postSaveUpdate,
  setViewStatus
}) => {
  const callout = useContext(CalloutContext);
  const queryClient = useQueryClient();
  const okapiKy = useOkapiKy();
  const createEditDetailsQuery = useOkapiQuery(areaCreateEditDetailsEndpoint);
  const recordQuery = useOkapiQuery(`${areaRecordEndpoint}${recordIdentifier}`, { enabled: !!recordIdentifier });

  const closeCreateEdit = (_from, modifyStatus) => {
    if (modifyStatus === MODIFY_STATUS_NONE) {
      setViewStatus(
        recordIdentifier === null ?
          STATUS_DETAILS_VIEW_CLOSED :
          STATUS_DETAILS_VIEW_OPEN
      );
    } else {
      setViewStatus(STATUS_DETAILS_VIEW_OPEN);
      postSaveUpdate(modifyStatus === MODIY_STATUS_CREATED);
    }
  };

  const updater = useMutation({
    mutationFn: (updated) => okapiKy
      .put(`${areaRecordEndpoint}${recordIdentifier}`, { json: updated })
      .then((res) => res.data),
    onSuccess: async (_res, updated) => {
      callout.sendCallout({
        message: <FormattedMessage
          id="ui-ill-ui.updated.record"
          values={{ name: updated.name }}
        />
      });
      await queryClient.invalidateQueries({ queryKey: [NAMESPACE_ILL, `${areaRecordEndpoint}${recordIdentifier}`], exact: false });
      closeCreateEdit('Updated', MODIFY_STATUS_UPDATED);
    },
    onError: async (err) => {
      callout.sendCallout({
        type: 'error',
        message: (
          <KeyValue
            label={<FormattedMessage id="ui-ill-ui.update.error" />}
            value={err.response?.messages || ''}
          />
        )
      });
    }
  });

  const creator = useMutation({
    mutationFn: (newRecord) => okapiKy
      .post(areaEndpoint, { json: newRecord }),
    onSuccess: async (res) => {
      const created = await res.json();
      callout.sendCallout({
        message: <FormattedMessage
          id="ui-ill-ui.created.record"
          values={{ name: created.name }}
        />
      });
      closeCreateEdit('Created', MODIY_STATUS_CREATED);
    },
    onError: async (err) => {
      callout.sendCallout({ type: 'error',
        message: (
          <KeyValue
            label={<FormattedMessage id="ui-ill-ui.create.error" />}
            value={err.response?.messages || ''}
          />
        ) });
    }
  });

  if (!createEditDetailsQuery.isSuccess) {
    // Not received the details for creating or editing the record yet, so bale out
    return null;
  }

  const isEditing = typeof recordIdentifier === 'string';
  let record = null;
  if (isEditing) {
    if (!recordQuery.isSuccess) {
      // Not received the details for creating or editing the record yet, so bale out
      return null;
    }
    record = recordQuery.data;
  }
  const initialValues = onInitialiseValues(record);

  const submit = newRecord => {
    onBeforeSave(newRecord);
    if (isEditing) {
      return updater.mutateAsync(newRecord);
    }
    const baseRecord = {
    };
    return creator.mutateAsync({ ...baseRecord, ...newRecord });
  };

  const renderLastMenu = (pristine, submitting, handleSubmit) => {
    let buttonId;
    let buttonLabel;
    if (isEditing) {
      buttonId = 'clickble-update-generic-entry';
      buttonLabel = <FormattedMessage id="ui-ill-ui.update" />;
    } else {
      buttonId = 'clickable-create-entry';
      buttonLabel = <FormattedMessage id="ui-ill-ui.create" />;
    }

    return (
      <PaneMenu>
        <Button
          id={buttonId}
          type="submit"
          disabled={pristine || submitting}
          onClick={handleSubmit}
          buttonStyle="primary paneHeaderNewButton"
          marginBottom0
        >
          {buttonLabel}
        </Button>
      </PaneMenu>
    );
  };

  return (
    <Form onSubmit={submit} initialValues={initialValues} mutators={{ handleSISelect }} keepDirtyOnReinitialize>
      {({ form, handleSubmit, pristine, submitting, submitSucceeded }) => (
        <Pane
          dismissible
          onClose={() => closeCreateEdit('pane.onClose', MODIFY_STATUS_NONE)}
          lastMenu={renderLastMenu(pristine, submitting, handleSubmit, isEditing)}
          noOverflow
          padContent={false}
          defaultWidth={PANE_WIDTH_DEFAULT}
          centerContent
          paneTitle={<FormattedMessage id={isEditing ? `ui-ill-ui.${messageIdPrefix}.update` : `ui-ill-ui.${messageIdPrefix}.create`} />}
        >
          <Card
            headerComponent={() => { return (''); }}
            headerStart=""
            cardClass={css.detailsViewCreateEditCard}
          >
            <form onSubmit={handleSubmit} id="form-ill-entry">
              <FormComponent
                createEditDetails={createEditDetailsQuery.data}
                onSISelect={form.mutators.handleSISelect}
              />
            </form>
          </Card>
          <FormattedMessage id="ui-ill-ui.confirmDirtyNavigate">
            {prompt => <Prompt when={!pristine && !(submitting || submitSucceeded)} message={prompt[0]} />}
          </FormattedMessage>
        </Pane>
      )}
    </Form>
  );
};

export default DetailsViewCreateEdit;
