/* eslint-disable import/prefer-default-export */
import {
  FormattedMessage
} from 'react-intl';

import {
  INSTITUTION_USER_CREATE,
  INSTITUTION_USER_DELETE,
  INSTITUTION_USER_EDIT
} from '../../../constants/permissions';

import {
  INSTITUTION_USER_ENDPOINT,
  INSTITUTION_USER_WITH_SEPARATOR_ENDPOINT,
  INSTITUTION_USER_CREATE_EDIT_DETAILS_ENDPOINT
} from '../../../constants/endpoints';

import {
  SYSTEM_AREA_INSTITUTION,
  SYSTEM_AREA_INSTITUTION_GROUP,
  SYSTEM_AREA_SHARED_INDEX_QUERY,
  SYSTEM_AREA_SETTINGS
} from '../SystemAreas';

import {
  SYSTEM_SEARCH_ARRAY_ALL_NAME_DESCRIPTION
} from '../SystemSearchFields';

import SystemSearchAndSort from '../SystemSearchAndSort';
import InstitutionUser from './InstitutionUser';
import InstitutionUserForm from './InstitutionUserForm';

const InstitutionUsers = ({
  institutionUsersQuery,
  queryGetter,
  querySetter,
  children
}) => {
  const onIninitialiseValues = (record) => {
    let initialValues;
    if (record === null) {
      initialValues = {
        tGroups: []
      };
    } else {
      initialValues = {
        ...record,
        tGroups: record.institutionGroups.map((group) => {
          return ({ label: group.name, value: group.id });
        })
      };
    }
    return (initialValues);
  };

  const onBeforeSave = (record) => {
    // Setup the groups correctly
    record.institutionGroups = record.tGroups.map((group) => {
      return ({ id: group.value });
    });
  };

  return (
    <SystemSearchAndSort
      area="institutionUser"
      messageIdPrefix="institution.user"
      detailsTitleMessageId="institution.user"
      searchableIndexes={SYSTEM_SEARCH_ARRAY_ALL_NAME_DESCRIPTION}
      visibleColumns={[
        'name',
        'folioUserId'
      ]}
      columnMappings={{
        name: <FormattedMessage id="ui-ill-ui.institution.users.name" />,
        folioUserId: <FormattedMessage id="ui-ill-ui.institution.users.folioUserId" />
      }}
      formatter={{
      }}
      idField="id"
      nameField="name"
      createPerm={INSTITUTION_USER_CREATE}
      deletePerm={INSTITUTION_USER_DELETE}
      editPerm={INSTITUTION_USER_EDIT}
      links={[
        SYSTEM_AREA_INSTITUTION,
        SYSTEM_AREA_INSTITUTION_GROUP,
        SYSTEM_AREA_SHARED_INDEX_QUERY,
        SYSTEM_AREA_SETTINGS
      ]}
      query={institutionUsersQuery}
      queryGetter={queryGetter}
      querySetter={querySetter}
      ViewPane={InstitutionUser}
      areaCreateEditDetailsEndpoint={INSTITUTION_USER_CREATE_EDIT_DETAILS_ENDPOINT}
      areaRecordEndpoint={INSTITUTION_USER_WITH_SEPARATOR_ENDPOINT}
      areaEndpoint={INSTITUTION_USER_ENDPOINT}
      FormComponent={InstitutionUserForm}
      onInitialiseValues={onIninitialiseValues}
      onBeforeSave={onBeforeSave}
      {...children}
    />
  );
};

export { InstitutionUsers };
