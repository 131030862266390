import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from 'react-final-form';
import { useMutation, useQueryClient } from 'react-query';
import { Prompt, useLocation } from 'react-router-dom';
import { Button, Pane, Paneset, PaneMenu, KeyValue } from '@folio/stripes/components';
import { CalloutContext, useOkapiKy } from '@folio/stripes/core';
import { useOkapiQuery } from '@k-int/stripes-ill';
import PatronRequestForm from '../components/PatronRequestForm';
import {
  PATRON_REQUESTS_ENDPOINT,
  PATRON_REQUESTS_WITH_SEPARATOR_ENDPOINT,
  PATRON_REQUESTS_NEW_REQUEST_DETAILS_ENDPOINT
} from '../constants/endpoints';

const handleSISelect = (args, state, tools) => {
  Object.entries(args[0]).forEach(([field, value]) => tools.changeValue(state, field, () => value));
};

const renderLastMenu = (pristine, submitting, submit, isEditing) => {
  let id;
  let label;
  if (isEditing) {
    id = 'clickble-update-ill-entry';
    label = <FormattedMessage id="ui-ill-ui.updatePatronRequest" />;
  } else {
    id = 'clickable-create-ill-entry';
    label = <FormattedMessage id="ui-ill-ui.createPatronRequest" />;
  }

  return (
    <PaneMenu>
      <Button
        id={id}
        type="submit"
        disabled={pristine || submitting}
        onClick={submit}
        buttonStyle="primary paneHeaderNewButton"
        marginBottom0
      >
        {label}
      </Button>
    </PaneMenu>
  );
};

const CreateEditRoute = props => {
  const { history, match } = props;
  const id = match.params?.id;
  const routerLocation = useLocation();
  const callout = useContext(CalloutContext);
  const intl = useIntl();
  const queryClient = useQueryClient();
  const okapiKy = useOkapiKy();

  const requestNewDetailsQuery = useOkapiQuery(PATRON_REQUESTS_NEW_REQUEST_DETAILS_ENDPOINT);
  const reqQuery = useOkapiQuery(`${PATRON_REQUESTS_WITH_SEPARATOR_ENDPOINT}${id}`, { enabled: !!id });

  const updater = useMutation({
    mutationFn: (updated) => okapiKy
      .put(`${PATRON_REQUESTS_WITH_SEPARATOR_ENDPOINT}${id}`, { json: updated })
      .then((res) => res.data),
    onSuccess: async () => {
      await new Promise(resolve => setTimeout(resolve, 3000));
      await queryClient.invalidateQueries(`${PATRON_REQUESTS_WITH_SEPARATOR_ENDPOINT}${id}`);
      await queryClient.invalidateQueries(PATRON_REQUESTS_ENDPOINT);
      history.goBack();
    },
    onError: async (err) => {
      callout.sendCallout({ type: 'error',
        message: (
          <KeyValue
            label={<FormattedMessage id="ui-ill-ui.update.error" />}
            value={err.response?.statusText || ''}
          />
        ) });
    },
  });

  const creator = useMutation({
    mutationFn: (newRecord) => okapiKy
      .post(PATRON_REQUESTS_ENDPOINT, { json: newRecord }),
    onSuccess: async (res) => {
      const created = await res.json();
      // When creating a new request we need to delay before redirecting to the request's page to
      // give the server some time to resolve the requesting institution from the symbol and generate
      // an appropriate ID.
      await new Promise(resolve => setTimeout(resolve, 3000));
      // We want to go to the new record but we also want it to be easy to return to where we were,
      // hence use of history.replace rather than history.push -- the create form turns into the
      // created record.
      history.replace(`view/${created.id}${routerLocation.search}`);
    },
    onError: async (err) => {
      callout.sendCallout({ type: 'error',
        message: (
          <KeyValue
            label={<FormattedMessage id="ui-ill-ui.create.error" />}
            value={err.response?.statusText || ''}
          />
        ) });
    },
  });

  if (!requestNewDetailsQuery.isSuccess) return null;
  const locations = requestNewDetailsQuery.data.pickupLocations;
  const requesters = requestNewDetailsQuery.data.requesterInstitutions;

  if (requesters.length === 0) throw new Error('Cannot resolve symbol to create requests as');

  const isEditing = typeof match.params.id === 'string';
  let initialValues = {};
  if (isEditing) {
    if (!reqQuery.isSuccess) return null;
    const record = reqQuery.data;
    initialValues = { ...record,
      formattedDateCreated: (
        intl.formatDate(record.dateCreated) + ', ' + intl.formatTime(record.dateCreated)
      ) };
  }

  const submit = newRecord => {
    if (isEditing) return updater.mutateAsync(newRecord);
    const baseRecord = {
      requestingInstitutionSymbol: requesters[0].value,
      isRequester: true
    };
    return creator.mutateAsync({ ...baseRecord, ...newRecord });
  };

  return (
    <Paneset>
      <Form onSubmit={submit} initialValues={initialValues} mutators={{ handleSISelect }} keepDirtyOnReinitialize>
        {({ form, handleSubmit, pristine, submitting, submitSucceeded }) => (
          <Pane
            defaultWidth="100%"
            centerContent
            onClose={history.goBack}
            dismissible
            lastMenu={renderLastMenu(pristine, submitting, handleSubmit, isEditing)}
            paneTitle={<FormattedMessage id={isEditing ? 'ui-ill-ui.updatePatronRequest' : 'ui-ill-ui.createPatronRequest'} />}
          >
            <form onSubmit={handleSubmit} id="form-ill-entry">
              <PatronRequestForm locations={locations} requesters={requesters} onSISelect={form.mutators.handleSISelect} />
            </form>
            <FormattedMessage id="ui-ill-ui.confirmDirtyNavigate">
              {prompt => <Prompt when={!pristine && !(submitting || submitSucceeded)} message={prompt[0]} />}
            </FormattedMessage>
          </Pane>
        )}
      </Form>
    </Paneset>
  );
};

export default CreateEditRoute;
