/* eslint-disable react/jsx-fragments */
import {
  Fragment
} from 'react';

import {
  FormattedMessage
} from 'react-intl';

import {
  Card
} from '@folio/stripes/components';

import {
  useOkapiQuery
} from '@k-int/stripes-ill';

import {
  SHARED_INDEX_FIND_MORE_SUPPLIERS_ENDPOINT
} from '../../../constants/endpoints';

import css from './DeterminedSuppliers.css';

const DeterminedSuppliers = ({ bibClusterId }) => {
  const determinedSuppliersQuery = useOkapiQuery(SHARED_INDEX_FIND_MORE_SUPPLIERS_ENDPOINT, {
    searchParams: {
      clusterSystemIdentifierId: bibClusterId
    }
  });

  if (!determinedSuppliersQuery.isSuccess) {
    return (null);
  }

  const record = determinedSuppliersQuery.data;
  const copiesFound = record.copiesFound;
  const suppliersFound = record.suppliersFound;

  return (
    <Fragment>
      <Card
        key={record.id}
        headerStart={<FormattedMessage id="ui-ill-ui.sharedIndex.record.determinedSuppliers.copiesFound" />}
        roundedBorder
      >
        {copiesFound.length > 0 &&
          copiesFound.map((entry, i) => (
            <Card
              key={i}
              headerComponent={() => { return (''); }}
              headerStart=""
              cardClass={css.determinedSuppliersCard}
              roundedBorder
            >
              <table className={css.determinedSuppliers}>
                <tbody>
                  {entry.symbol &&
                    <tr>
                      <td>
                        <FormattedMessage id="ui-ill-ui.sharedIndex.record.determinedSuppliers.copiesFound.symbol" />
                      </td>
                      <td>
                        {entry.symbol}
                      </td>
                    </tr>
                  }
                  {entry.instanceIdentifier &&
                    <tr>
                      <td>
                        <FormattedMessage id="ui-ill-ui.sharedIndex.record.determinedSuppliers.copiesFound.instanceIdentifier" />
                      </td>
                      <td>
                        {entry.instanceIdentifier}
                      </td>
                    </tr>
                  }
                  {entry.copyIdentifier &&
                    <tr>
                      <td>
                        <FormattedMessage id="ui-ill-ui.sharedIndex.record.determinedSuppliers.copiesFound.copyIdentifier" />
                      </td>
                      <td>
                        {entry.copyIdentifier}
                      </td>
                    </tr>
                  }
                  {entry.illPolicy &&
                    <tr>
                      <td>
                        <FormattedMessage id="ui-ill-ui.sharedIndex.record.determinedSuppliers.copiesFound.illPolicy" />
                      </td>
                      <td>
                        {entry.illPolicy}
                      </td>
                    </tr>
                  }
                  {entry.totalCopies &&
                    <tr>
                      <td>
                        <FormattedMessage id="ui-ill-ui.sharedIndex.record.determinedSuppliers.copiesFound.totalCopies" />
                      </td>
                      <td>
                        {entry.totalCopies}
                      </td>
                    </tr>
                  }
                  {entry.availableCopies &&
                    <tr>
                      <td>
                        <FormattedMessage id="ui-ill-ui.sharedIndex.record.determinedSuppliers.copiesFound.availableCopies" />
                      </td>
                      <td>
                        {entry.availableCopies}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </Card>
          ))
        }
      </Card>
      <Card
        key={record.id}
        headerStart={<FormattedMessage id="ui-ill-ui.sharedIndex.record.determinedSuppliers.suppliersFound" />}
        roundedBorder
      >
        {suppliersFound.length > 0 &&
          suppliersFound.map((entry, i) => (
            <Card
              key={i}
              headerComponent={() => { return (''); }}
              headerStart=""
              cardClass={css.determinedSuppliersCard}
              roundedBorder
            >
              <table className={css.determinedSuppliers}>
                <tbody>
                  {entry.supplier_symbol &&
                    <tr>
                      <td>
                        <FormattedMessage id="ui-ill-ui.sharedIndex.record.determinedSuppliers.suppliersFound.supplier_symbol" />
                      </td>
                      <td>
                        {entry.supplier_symbol}
                      </td>
                    </tr>
                  }
                  {entry.instance_identifier &&
                    <tr>
                      <td>
                        <FormattedMessage id="ui-ill-ui.sharedIndex.record.determinedSuppliers.suppliersFound.instance_identifier" />
                      </td>
                      <td>
                        {entry.instance_identifier}
                      </td>
                    </tr>
                  }
                  {entry.copy_identifier &&
                    <tr>
                      <td>
                        <FormattedMessage id="ui-ill-ui.sharedIndex.record.determinedSuppliers.suppliersFound.copy_identifier" />
                      </td>
                      <td>
                        {entry.copy_identifier}
                      </td>
                    </tr>
                  }
                  {entry.ill_policy &&
                    <tr>
                      <td>
                        <FormattedMessage id="ui-ill-ui.sharedIndex.record.determinedSuppliers.suppliersFound.ill_policy" />
                      </td>
                      <td>
                        {entry.ill_policy}
                      </td>
                    </tr>
                  }
                  <tr>
                    <td>
                      <FormattedMessage id="ui-ill-ui.sharedIndex.record.determinedSuppliers.suppliersFound.rank" />
                    </td>
                    <td>
                      {entry.rank}
                    </td>
                  </tr>
                  {entry.rankReason &&
                    <tr>
                      <td>
                        <FormattedMessage id="ui-ill-ui.sharedIndex.record.determinedSuppliers.suppliersFound.rankReason" />
                      </td>
                      <td>
                        {entry.rankReason}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </Card>
          ))
        }
      </Card>
    </Fragment>
  );
};

export default DeterminedSuppliers;
