import GenericInfo from './GenericInfo';
import css from './InstitutionInfo.css';

const GroupInstitutionInfo = ({ institutions }) => {
  return (
    <GenericInfo
      titleId="institutions"
      list={institutions}
      linkPath="institution"
      cssCard={css.institutionCard}
      cssCardHeader={css.institutionCardHeader}
      cssTable={css.institutionEntryList}
    />
  );
};

export default GroupInstitutionInfo;
