export const SYSTEM_SEARCH_ALL_FIELDS =
{
  label: 'allFields',
  value: ''
};

export const SYSTEM_SEARCH_NAME =
{
  label: 'name',
  value: 'name'
};

export const SYSTEM_SEARCH_DESCRIPTION =
{
  label: 'description',
  value: 'description'
};

export const SYSTEM_SEARCH_ARRAY_ALL_NAME_DESCRIPTION =
[
  SYSTEM_SEARCH_ALL_FIELDS,
  SYSTEM_SEARCH_NAME,
  SYSTEM_SEARCH_DESCRIPTION
];

export const SYSTEM_SEARCH_ARRAY_ALL =
[
  SYSTEM_SEARCH_ALL_FIELDS,
];
