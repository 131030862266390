/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  FormattedMessage
} from 'react-intl';

import {
  Card
} from '@folio/stripes/components';

import css from './StringList.css';

const StringList = ({ parentId, listMsgId, data }) => {
  return (
    <Card
      id={`${parentId}-${listMsgId}-card`}
      headerStart={<FormattedMessage id={`ui-ill-ui.sharedIndex.record.${listMsgId}`} />}
      cardClass={css.stringListCard}
      headerClass={css.stringListCard}
      roundedBorder
    >
      <table className={css.stringList}>
        <tbody>
          { data &&
            data.map((entry, i) => (
              <tr key={i}>
                <td>
                  {entry}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </Card>
  );
};

export default StringList;
