/* eslint-disable import/prefer-default-export */
export const REQUEST_ACTIONS = {
  cancelLocal: 'cancelLocal',
  fillLocally: 'fillLocally',
  fillMultiVolumeRequest: 'fillMultiVolumeRequest',
  manualClose: 'manualClose',
  message: 'message',
  messageSeen: 'messageSeen',
  messagesAllSeen: 'messagesAllSeen',
  patronReturnedItem: 'patronReturnedItem',
  patronReturnedItemAndShippedReturn: 'patronReturnedItemAndShippedReturn',
  requesterAgreeConditions: 'requesterAgreeConditions',
  requesterCancel: 'requesterCancel',
  requesterManualCheckIn: 'requesterManualCheckIn',
  requesterReceived: 'requesterReceived',
  respondYes: 'respondYes',
  shippedReturn: 'shippedReturn',
  supplierCannotSupply: 'supplierCannotSupply',
  supplierCheckInToIll: 'supplierCheckInToIll',
  supplierCheckInToIllAndSupplierMarkShipped: 'supplierCheckInToIllAndSupplierMarkShipped',
  supplierCheckOutOfIll: 'supplierCheckOutOfIll',
  supplierConditionalSupply: 'supplierConditionalSupply',
  supplierFillDigitalLoan: 'supplierFillDigitalLoan',
  supplierManualCheckout: 'supplierManualCheckout',
  supplierMarkConditionsAgreed: 'supplierMarkConditionsAgreed',
  supplierMarkShipped: 'supplierMarkShipped',
  supplierPrintPullSlip: 'supplierPrintPullSlip',
  supplierRespondToCancel: 'supplierRespondToCancel'
};
