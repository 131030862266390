/* eslint-disable react/jsx-fragments */
import {
  useContext,
  useState,
  Fragment
} from 'react';
import {
  useQueryClient
} from 'react-query';
import {
  useIntl
} from 'react-intl';

import {
  Label,
  Select
} from '@folio/stripes/components';
import {
  CalloutContext,
  useOkapiKy
} from '@folio/stripes/core';

import {
  useOkapiQuery,
  NAMESPACE_ILL
} from '@k-int/stripes-ill';

import {
  INSTITUTION_USER_CAN_MANAGE_ENDPOINT,
  INSTITUTION_USER_MANAGE_INSTITUTION_ENDPOINT
} from '../../../constants/endpoints';

const ChangeInstitution = () => {
  const okapiKy = useOkapiKy();
  const queryClient = useQueryClient();
  const callout = useContext(CalloutContext);
  const intl = useIntl();

  const [institutionManaging, setInstitutionManaging] = useState(null);
  const [institutionsCanManage, setInstitutionsCanManage] = useState([]);

  const institutionsCanManageQuery = useOkapiQuery(INSTITUTION_USER_CAN_MANAGE_ENDPOINT, { searchParams: { perPage: '150' }, staleTime: 30 * 60 * 1000 });
  if ((institutionManaging === null) && institutionsCanManageQuery.isSuccess) {
    setInstitutionsCanManage(institutionsCanManageQuery.data.institutions);
    setInstitutionManaging(institutionsCanManageQuery.data.currentlyManaging);
  }

  const onChangeManagingInstitution = (event) => {
    const institutionToManage = event.target.value;
    okapiKy(INSTITUTION_USER_MANAGE_INSTITUTION_ENDPOINT, { searchParams: { institution: institutionToManage } }).then(async res => {
      // Reset the ill queries as we have now changed the institution we are dealing with
      queryClient.resetQueries({ queryKey: [NAMESPACE_ILL], exact: false });
      setInstitutionManaging(institutionToManage);
    }).catch(async e => {
      const res = await e?.response?.json();
      const message = intl.formatMessage({ id: 'ui-ill-ui.error.setting.managing.institution' }, { errMsg: res?.error ?? e.message });
      callout.sendCallout({ type: 'error', message });
    });
  };

  return (
    <Fragment>
      {(institutionsCanManage.length > 1) ?
        <Select
          id="changeInstitution-institutionUser-Manage"
          marginBottom0
          value={institutionManaging}
          dataOptions={institutionsCanManage}
          onChange={onChangeManagingInstitution}
        />
        :
        (institutionsCanManage.length === 1) ?
          <Label>
            {institutionsCanManage[0].label}
          </Label>
          : ''}
    </Fragment>
  );
};

export default ChangeInstitution;
