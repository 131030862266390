/* eslint-disable import/prefer-default-export */
import {
  FormattedMessage
} from 'react-intl';

import {
  INSTITUTION_GROUP_CREATE,
  INSTITUTION_GROUP_DELETE,
  INSTITUTION_GROUP_EDIT
} from '../../../constants/permissions';

import {
  INSTITUTION_GROUP_ENDPOINT,
  INSTITUTION_GROUP_WITH_SEPARATOR_ENDPOINT,
  INSTITUTION_GROUP_CREATE_EDIT_DETAILS_ENDPOINT
} from '../../../constants/endpoints';

import {
  SYSTEM_AREA_INSTITUTION,
  SYSTEM_AREA_INSTITUTION_USER,
  SYSTEM_AREA_SHARED_INDEX_QUERY,
  SYSTEM_AREA_SETTINGS
} from '../SystemAreas';

import {
  SYSTEM_SEARCH_ARRAY_ALL_NAME_DESCRIPTION
} from '../SystemSearchFields';

import SystemSearchAndSort from '../SystemSearchAndSort';
import InstitutionGroup from './InstitutionGroup';
import InstitutionGroupForm from './InstitutionGroupForm';

const InstitutionGroups = ({
  institutionGroupsQuery,
  queryGetter,
  querySetter,
  children
}) => {
  const getActionMenu = () => (
    ''
  );

  const onIninitialiseValues = (record) => {
    let initialValues;
    if (record === null) {
      initialValues = {
        tInstitutions: [],
        tUsers: []
      };
    } else {
      initialValues = {
        ...record,
        tInstitutions: record.institutions.map((institution) => {
          return ({ label: institution.name, value: institution.id });
        }),
        tUsers: record.institutionUsers.map((user) => {
          return ({ label: user.name, value: user.id });
        })
      };
    }
    return (initialValues);
  };

  const onBeforeSave = (record) => {
    // Setup the institutions and users correctly
    record.institutions = record.tInstitutions.map((institution) => {
      return ({ id: institution.value });
    });
    record.institutionUsers = record.tUsers.map((user) => {
      return ({ id: user.value });
    });
  };

  return (
    <SystemSearchAndSort
      area="institutionGroup"
      messageIdPrefix="institution.group"
      detailsTitleMessageId="institution.group"
      searchableIndexes={SYSTEM_SEARCH_ARRAY_ALL_NAME_DESCRIPTION}
      actionsMenu={getActionMenu}
      visibleColumns={[
        'name',
        'description'
      ]}
      columnMappings={{
        name: <FormattedMessage id="ui-ill-ui.institution.groups.name" />,
        description: <FormattedMessage id="ui-ill-ui.institution.groups.description" />
      }}
      formatter={{
      }}
      idField="id"
      nameField="name"
      createPerm={INSTITUTION_GROUP_CREATE}
      deletePerm={INSTITUTION_GROUP_DELETE}
      editPerm={INSTITUTION_GROUP_EDIT}
      links={[
        SYSTEM_AREA_INSTITUTION,
        SYSTEM_AREA_INSTITUTION_USER,
        SYSTEM_AREA_SHARED_INDEX_QUERY,
        SYSTEM_AREA_SETTINGS
      ]}
      query={institutionGroupsQuery}
      queryGetter={queryGetter}
      querySetter={querySetter}
      ViewPane={InstitutionGroup}
      areaCreateEditDetailsEndpoint={INSTITUTION_GROUP_CREATE_EDIT_DETAILS_ENDPOINT}
      areaRecordEndpoint={INSTITUTION_GROUP_WITH_SEPARATOR_ENDPOINT}
      areaEndpoint={INSTITUTION_GROUP_ENDPOINT}
      FormComponent={InstitutionGroupForm}
      onInitialiseValues={onIninitialiseValues}
      onBeforeSave={onBeforeSave}
      {...children}
    />
  );
};

export { InstitutionGroups };
