/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  FormattedMessage
} from 'react-intl';

import {
  Card
} from '@folio/stripes/components';

import css from './SharedIndexAvailability.css';

const SharedIndexAvailability = ({ record }) => {
  return (
    <Card
      key={record.id}
      headerComponent={() => { return (''); }}
      headerStart=""
      cardClass={css.sharedIndexAvailabilityCard}
      roundedBorder
    >
      <table className={css.sharedIndexAvailability}>
        <tbody>
          {record.agency?.code &&
            <tr>
              <td>
                <FormattedMessage id="ui-ill-ui.sharedIndex.record.availability.agency.code" />
              </td>
              <td>
                {record.agency.code}
              </td>
            </tr>
          }
          {record.agency?.description &&
            <tr>
              <td>
                <FormattedMessage id="ui-ill-ui.sharedIndex.record.availability.agency.description" />
              </td>
              <td>
                {record.agency.description}
              </td>
            </tr>
          }
          {record.barcode &&
            <tr>
              <td>
                <FormattedMessage id="ui-ill-ui.sharedIndex.record.availability.barcode" />
              </td>
              <td>
                {record.barcode}
              </td>
            </tr>
          }
          {record.callNumber &&
            <tr>
              <td>
                <FormattedMessage id="ui-ill-ui.sharedIndex.record.availability.callNumber" />
              </td>
              <td>
                {record.callNumber}
              </td>
            </tr>
          }
          {record.canonicalItemType &&
            <tr>
              <td>
                <FormattedMessage id="ui-ill-ui.sharedIndex.record.availability.canonicalItemType" />
              </td>
              <td>
                {record.canonicalItemType}
              </td>
            </tr>
          }
          <tr>
            <td>
              <FormattedMessage id="ui-ill-ui.sharedIndex.record.availability.holdCount" />
            </td>
            <td>
              {record.holdCount}
            </td>
          </tr>
          {record.hostLmsCode &&
            <tr>
              <td>
                <FormattedMessage id="ui-ill-ui.sharedIndex.record.availability.hostLmsCode" />
              </td>
              <td>
                {record.hostLmsCode}
              </td>
            </tr>
          }
          {record.isRequestable &&
            <tr>
              <td>
                <FormattedMessage id="ui-ill-ui.sharedIndex.record.availability.isRequestable" />
              </td>
              <td>
                {record.isRequestable}
              </td>
            </tr>
          }
          {record.localItemType &&
            <tr>
              <td>
                <FormattedMessage id="ui-ill-ui.sharedIndex.record.availability.localItemType" />
              </td>
              <td>
                {record.localItemType}
              </td>
            </tr>
          }
          {record.localItemTypeCode &&
            <tr>
              <td>
                <FormattedMessage id="ui-ill-ui.sharedIndex.record.availability.localItemTypeCode" />
              </td>
              <td>
                {record.localItemTypeCode}
              </td>
            </tr>
          }
          {record.location?.code &&
            <tr>
              <td>
                <FormattedMessage id="ui-ill-ui.sharedIndex.record.availability.location.code" />
              </td>
              <td>
                {record.location.code}
              </td>
            </tr>
          }
          {record.location?.name &&
            <tr>
              <td>
                <FormattedMessage id="ui-ill-ui.sharedIndex.record.availability.location.name" />
              </td>
              <td>
                {record.location.name}
              </td>
            </tr>
          }
          {record.status?.code &&
            <tr>
              <td>
                <FormattedMessage id="ui-ill-ui.sharedIndex.record.availability.status.code" />
              </td>
              <td>
                {record.status.code}
              </td>
            </tr>
          }
        </tbody>
      </table>
    </Card>
  );
};

export default SharedIndexAvailability;
