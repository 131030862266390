/* eslint-disable jsx-a11y/control-has-associated-label */
// (it doesn't recognise FormattedMessage as a text label for th)
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Card } from '@folio/stripes/components';
import css from './GroupInfo.css';

const GroupInfo = ({ groups, parentId }) => {
  return (
    <Card
      id={`${parentId}-groups-card`}
      headerStart={<FormattedMessage id="ui-ill-ui.institution.groups" />}
      cardClass={css.groupCard}
      headerClass={css.groupCardHeader}
      roundedBorder
    >
      <table className={css.groupEntryList}>
        <thead>
          <tr>
            <th><FormattedMessage id="ui-ill-ui.institution.group.name" /></th>
            <th><FormattedMessage id="ui-ill-ui.institution.group.description" /></th>
          </tr>
        </thead>
        <tbody>
          {
            groups.map((entry, i) => (
              <tr key={i}>
                <td>
                  <Link to={`../institutionGroup/${entry.id}`}>
                    {entry.name}
                  </Link>
                </td>
                <td>{entry.description}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </Card>
  );
};

export default GroupInfo;
