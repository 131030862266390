import React, {
  useContext
} from 'react';

import {
  FormattedMessage
} from 'react-intl';

import {
  useMutation
} from 'react-query';

import {
  CalloutContext,
  IfPermission,
  useOkapiKy
} from '@folio/stripes/core';

import {
  Button,
  Card,
  KeyValue,
  Pane,
  PaneMenu,
} from '@folio/stripes/components';

import {
  PANE_WIDTH_DEFAULT,
  STATUS_DETAILS_VIEW_CLOSED,
  STATUS_DETAILS_VIEW_EDIT
} from './DetailsViewConstants';

const DetailsView = ({
  record,
  area,
  _messageIdPrefix,
  detailsTitleMessageId,
  nameField,
  setDetailsViewStatus,
  ViewPane,
  deletePerm,
  editPerm,
  areaRecordEndpoint,
  postDelete
}) => {
  const callout = useContext(CalloutContext);
  const okapiKy = useOkapiKy();

  const onDelete = useMutation({
    mutationFn: () => okapiKy
      .delete(`${areaRecordEndpoint}${record.id}`)
      .then((res) => res.data),
    onSuccess: async (_res) => {
      callout.sendCallout({
        message: <FormattedMessage
          id="ui-ill-ui.deleted.record"
          values={{ name: record.name }}
        />
      });
      setDetailsViewStatus(STATUS_DETAILS_VIEW_CLOSED);
      postDelete(true);
    },
    onError: async (_err) => {
      callout.sendCallout({
        type: 'error',
        message: (
          <KeyValue
            label={<FormattedMessage id="ui-ill-ui.error.deleting.record" />}
          />
        )
      });
    },
  });

  return (
    <Pane
      dismissible
      onClose={() => {
        setDetailsViewStatus(STATUS_DETAILS_VIEW_CLOSED);
      }}
      lastMenu={(
        <PaneMenu>
          {deletePerm && (record.canDelete === true) &&
            <IfPermission key="0" perm={deletePerm}>
              <Button
                id={`clickable-ill-${area}.delete`}
                type="submit"
                onClick={() => onDelete.mutateAsync()}
                buttonStyle="primary paneHeaderNewButton"
                marginBottom0
              >
                <FormattedMessage id="ui-ill-ui.delete" />
              </Button>
            </IfPermission>
          }
          {editPerm &&
            <IfPermission key="1" perm={editPerm}>
              <Button
                id={`clickable-ill-${area}.edit`}
                type="submit"
                onClick={() => setDetailsViewStatus(STATUS_DETAILS_VIEW_EDIT)}
                buttonStyle="primary paneHeaderNewButton"
                marginBottom0
              >
                <FormattedMessage id="ui-ill-ui.edit" />
              </Button>
            </IfPermission>
          }
        </PaneMenu>
      )}
      defaultWidth={PANE_WIDTH_DEFAULT}
      padContent={false}
      paneTitle={<FormattedMessage id={`ui-ill-ui.${detailsTitleMessageId}`} values={{ name: record[nameField] }} />}
    >
      <Card
        headerComponent={() => { return (''); }}
        headerStart=""
      >
        <ViewPane
          id={record.id}
          record={record}
        />
      </Card>
    </Pane>
  );
};

export default DetailsView;
