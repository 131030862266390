import { useInfiniteQuery } from 'react-query';
import { useOkapiKy } from '@folio/stripes/core';
import { generateKiwtQuery, useKiwtSASQuery } from '@k-int/stripes-kint-components';
import { NAMESPACE_ILL } from '@k-int/stripes-ill';
import { InstitutionUsers } from '../../../components/System/Institutions';

import {
  INSTITUTION_USER_ENDPOINT
} from '../../../constants/endpoints';

const PER_PAGE = 100;

const InstitutionUsersRoute = ({ appName, children }) => {
  const { query, queryGetter, querySetter } = useKiwtSASQuery();
  const ky = useOkapiKy();

  const SASQ_MAP = {
    searchKey: 'name,description',
    sortKeys: {
    },
    perPage: PER_PAGE
  };

  const institutionUsersQuery = useInfiniteQuery(
    {
      queryKey: [NAMESPACE_ILL, INSTITUTION_USER_ENDPOINT, query, `@k-int/${appName}`],
      queryFn: ({ pageParam = 0 }) => ky(`${INSTITUTION_USER_ENDPOINT}${generateKiwtQuery({ offset: pageParam, ...SASQ_MAP }, query)}`).json(),
      useErrorBoundary: true,
      staleTime: 2 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      // we render before useKiwtSASQuery() finishes, let's prevent an extra, unnecessary, fetch
      // enabled: Object.prototype.hasOwnProperty.call(query, 'query'),
    }
  );

  return (
    <InstitutionUsers
      institutionUsersQuery={institutionUsersQuery}
      queryGetter={queryGetter}
      querySetter={querySetter}
    >
      {children}
    </InstitutionUsers>
  );
};

export default InstitutionUsersRoute;
