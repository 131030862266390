/* eslint-disable jsx-a11y/control-has-associated-label */
// (it doesn't recognise FormattedMessage as a text label for th)
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Card } from '@folio/stripes/components';
import { useOkapiQuery } from '@k-int/stripes-ill';
import { INSTITUTION_USER_WITH_SEPARATOR_ENDPOINT } from '../../../../constants/endpoints';
import GroupTable from './GroupTable';
import css from './InstitutionInfo.css';

const InstitutionInfo = ({ parentId }) => {
  const { data: users = {}, isSuccess } = useOkapiQuery(`${INSTITUTION_USER_WITH_SEPARATOR_ENDPOINT}${parentId}/institutions`);

  // Have we managed to get the data
  if (!isSuccess) {
    return (null);
  }

  return (
    <Card
      id={`${parentId}-institutions-card`}
      headerStart={<FormattedMessage id="ui-ill-ui.institutions" />}
      cardClass={css.institutionCard}
      headerClass={css.institutionCardHeader}
      roundedBorder
    >
      <table className={css.institutionEntryList}>
        <thead>
          <tr>
            <th><FormattedMessage id="ui-ill-ui.institution.name" /></th>
            <th><FormattedMessage id="ui-ill-ui.institution.groups" /></th>
          </tr>
        </thead>
        <tbody>
          {
            users.map((entry, i) => (
              <tr key={i}>
                <td>
                  <Link to={`../institution/${entry.institution.id}`}>
                    {entry.institution.name}
                  </Link>
                </td>
                <td>
                  <GroupTable groups={entry.groups} />
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </Card>
  );
};

export default InstitutionInfo;
